import { HStack } from '@chakra-ui/react';

export const hegData = [
  <div key="asd12wd" className="textsContainer">
    <HStack
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      alignItems={'center'}
      w={'full'}
      gap={'2rem'}
    >
      <img className="i0" src="/images/heg.jpeg" alt="Cerebrium HEG-NIL" />
      <p className="formated-text" style={{ maxWidth: '800px' }}>
        Antrenamentul neurofeedback este cel mai adesea identificat cu
        activitatea electrică a creierului. Cu toate acestea, activitatea de
        alimentare cu sânge a creierului sau fluxul sanguin cerebral regional
        (rCBF) este de asemenea de mare interes, deoarece echipamentul de
        neurofeedback hemoencefalografic (HEG) poate ajuta clienții să-și
        îmbunătățească fluxul sanguin cerebral în zone cheie ale creierului. În
        unele cazuri, HEG neurofeedback este mai eficient decât EEG
        neurofeedback.
      </p>
    </HStack>
    <p className="formated-text">
      Nicio activitate a undelor cerebrale nu are loc dacă creierul nu este
      alimentat cu suficient oxigen și glucoză. Fluxul sanguin cerebral
      transportă aceste elemente necesare la diferite structuri ale creierului
      pentru a preveni deteriorarea acestuia.
    </p>
    <p className="formated-text">
      Creierul adult are nevoie de 750 mililitri de sânge oxigenat în fiecare
      minut pentru a menține activitățile normale. Din cantitatea totală de
      oxigen livrată țesuturilor corpului de artere 20% este consumată doar de
      creier. În condiții normale, întreruperea fluxului sanguin către creier
      timp de 5 până la 10 de secunde este suficientă pentru a provoca
      modificări temporare ale activității neuro-mentale (leziuni neuronale
      iremediabile).
    </p>
    <p className="formated-text">
      <u>Accidentul vascular cerebral ischemic</u> se referă la o întrerupere a
      alimentării cu sânge a creierului.
    </p>
    <p className="formated-text">
      <u>Accidentul vascular cerebral hemoragic</u> se referă la un anevrism
      cerebral sau o ruptură a unui vas de sânge.
    </p>
    <p className="formated-text">
      Regiunile subactive ale creierului pot avea o aprovizionare inadecvată de
      sânge oxigenat, ceea ce duce la o funcționare cognitivă slabă. De exemplu,
      TOC (tulburările obsesiv –compulsive), ADHD (deficitele de atenție cu
      hiperactivitate) și alte tulburări au o rată metabolică cerebrală
      anormală.
    </p>
    <p className="formated-text">
      Creierul care lucrează necesită mai mult sânge oxigenat în anumite regiuni
      atunci când execută o sarcină. Îmbunătățirea calității fluxului sanguin în
      creier se traduce printr-o capacitate mentală îmbunătățită. Cercetările
      efectuate de Lubar și colaboratorii au arătat relația dintre EEG, HEG și
      încetinirea corticală: Studiile neuroimagistice cognitive care utilizează
      măsuri CBF (fluxului sanguin cerebral) au arătat creșteri ale
      metabolismului cerebral în zonele creierului responsabile pentru diferite
      modalități de citire.
    </p>
    <p className="formated-text">
      <strong>
        <u>Creșterea</u>
      </strong>
      &nbsp; metabolismului cerebral a fost corelată cu&nbsp;
      <u>
        creșterea amplitudinii EEG de frecvență <strong>rapidă</strong>
      </u>
      ;
    </p>
    <p className="formated-text">
      Scăderea metabolismului cerebral a fost corelată cu
      <u>
        creșteri ale amplitudinii EEG cu frecvență <strong>lentă</strong>
      </u>
      .
    </p>
    <p className="formated-text">
      Hemoencefalografia (HEG) nu măsoară activitatea electrică a creierului și
      este în mare parte imună la artefactele musculare (SEMG) și de mișcare a
      ochilor (FOA). Nu necesită gel sau abraziune a scalpului. Senzorii cu
      infraroșu pot fi montați în mai puțin de un minut.
    </p>

    <p className="formated-text">
      Neurofeedback-ul include mai mult decât activitatea electrică a
      creierului. Rata metabolică a creierului poate fi de asemenea măsurată și
      antrenată. Atunci când creierul nu are suficientă cantitate de sânge
      pentru a efectua o sarcină specifică, se numește hipofuziune. În
      general,&nbsp;
      <strong>hipoperfuzia</strong> corespunde rapoartelor&nbsp;
      <strong>theta-beta</strong> sau metabolismului mai&nbsp;
      <strong>lent</strong>. Pe de altă parte, <strong>hiperperfuzia</strong> se
      referă la un metabolism <strong>beta</strong> sau mai&nbsp;
      <strong>rapid</strong>. Multe sarcini mentale necesită sânge oxigenat
      adecvat în cortexul prefrontal.
    </p>

    <p className="formated-text">
      Protocoalele HEG variază. Antrenarea eficientă HEG-NIR se bazează pe
      următoarele concepte:
    </p>
    <ul className="unformated">
      <li>
        1.
        <strong>
          &nbsp;<u>Antrenament sub sarcină:</u>
        </strong>
        &nbsp; Folosirea unui software interactiv care descrie un avatar care:
        <ul style={{ margin: 0, padding: 0 }}>
          <li>
            • <strong>urcă</strong> pe monitor atunci când rata metabolică&nbsp;
            <strong>crește</strong>
          </li>
          <li>
            • <strong>coboară</strong> pe monitor atunci când&nbsp;
            <strong>scade</strong>.
          </li>
        </ul>
        Indiferent de sarcină, pacientul trebuie să fie implicat mental.
      </li>
      <li>
        2.
        <strong>
          &nbsp;<u>Antrenamentul cu filme</u>&nbsp;
        </strong>
        va duce probabil la eșec, deoarece pacientul poate fi hipnotizat de
        film, mai degrabă decât provocat mental. HEG-NIR vizează lobii frontali,
        iar în timpul vizionării de filme rareori se activează lobul frontal.
      </li>
      <li>
        3.
        <strong>
          &nbsp;<u>Antrenamentul cu sunete variabile</u>&nbsp;
        </strong>
        care au sunete bonus la vârfuri în fluxul sanguin oxigenat și nu cu
        sunete monotone repetitive. Un program poate utiliza numai grafică și
        niciun feedback sonor însă pentru ca acest program să funcționeze
        pacientul trebuie să rămână în sarcină.
      </li>
    </ul>
    <img
      className="heg-img i1"
      src="/images/heg1.jpg"
      alt="Cerebrium HEG-NIL"
    />
    <p className=" heg formated-text">
      Sângele oxigenat al lobului frontal crește atunci când este sub sarcină.
      Cele mai eficiente sunete oferă tonuri bonus uimitoare în timpul
      vârfurilor. Schimbarea vigilenței are loc în câteva minute, nu este
      subtil. Pacienții cu lobul frontal ridicat theta obțin cele mai bune
      rezultate de la HEG-NIR.(Figura 23.4)
    </p>

    <p className="formated-text">
      Neurofeedback-ul Hemoencefalografic are senzori care măsoară metabolismul
      lobului prefrontal, informații despre sângele oxigenat al lobului
      prefrontal și feedback-ul către pacient.
    </p>
    <p className="formated-text">
      Hemoencefalografia (HEG) este biofeedback-ul circulatoriu cortical care
      utilizează lumină refractată reglată la hemoglobina oxigenată, emisă în
      craniu și detectată la nivelul scalpului folosind o celulă fotoelectrică.
    </p>

    <p className="formated-text">
      Hershel Toomim a recomandat următorul antrenament:
    </p>
    <ul className="unformated">
      <li> 1. 10 minute la Fpz </li>
      <li> 2. 10 minute la FP2</li>
      <li> 3. 10 minute la Fpl </li>
    </ul>

    <p className="formated-text">
      Doi senzori sunt utilizați pentru a măsura calitatea sângelui circulant în
      HEG Neurofeedback: Unul proiectează lumina infraroșie spre interior, în
      timp ce celălalt captează razele care se întorc. În acest fel, este
      posibil să se determine culoarea sângelui în țesut. Țesutul roșu este
      bogat în oxigen, în timp ce țesutul albastru este epuizat de oxigen.
      Senzorii sunt montați pe o bandă elastică care se înfășoară în jurul
      capului și se fixează împreună cu Velcro. Nu este necesară nicio pastă sau
      pregătire specială. Se recomandă curățarea frunții clienților cu alcool
      pentru a preveni acumularea de sebum pe senzorii aparatului. Cea mai mare
      parte a antrenamentului va avea loc de-a lungul frunții, în special la
      girusul orbital, cortexul medial ventral sau lobii laterali prefrontali
      ventrali (Fpl, Fp2, Fpz, F7 sau F8). Banda este mutată dintr-o parte a
      frunții în alta. Mișcările musculare minore ale frunții, clipirile ochilor
      și alte mișcări faciale au efecte minime asupra luminii infraroșii. De
      reținut că punctul alb din centrul benzii de susținere Velcro este locația
      pentru antrenament.
    </p>
  </div>,
  <div key="asd13wd" className="textsContainer">
    <p className="formated-text">
      Există 4 niveluri în joc. La atingerea unui nou nivel, avatarul va începe
      la nivelul de bază într-un mediu nou.
    </p>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '2rem',
      }}
    >
      {' '}
      <img
        className="heg-img i2"
        src="/images/heg2.jpg"
        alt="Cerebrium HEG-NIL"
      />
      <img
        className="heg-img i3"
        src="/images/heg3.png"
        alt="Cerebrium HEG-NIL"
      />
    </div>
    <p className="formated-text">
      Funcționarea slabă a lobilor frontali este asociată cu multe tulburări
      mintale, cum ar fi schizofrenia, autismul, tulburările de învățare și
      ADHD.
    </p>{' '}
    <p className="formated-text">
      Hipoperfuzia se întâmplă atunci când anumite zone ale creierului sunt
      lipsite de sânge bogat în oxigen. În EEG undele lente au amplitudini mult
      mai mari decât undele rapide. De exemplu, dominanța undelor lente este
      indicată atunci când amplitudinile teta (4-8 Hz) sunt de cel puțin 2,5 ori
      mai mari decât amplitudinile beta (13-21 Hz) la Fpz (raporturile normale
      teta/beta sunt mai mari la copii și mai mici la adulți). Antrenarea
      clienților cu dominare rapidă este de obicei contraindicată.
    </p>{' '}
    <p className="formated-text">
      Neurofeedback-ul HEG este ideal pentru copii cu ticuri faciale și/sau
      încetinirea EEG pe partea prefrontală. Un astfel de copil se poate antrena
      la lobii prefrontali cu ochii deschiși și sub sarcină. Neurofeedback-ul
      HEG poate necesita mai puține sesiuni de antrenament decât
      neurofeedback-ul EEG pentru a îmbunătăți funcționarea executivă (Hershel
      Toomim, 2002).
    </p>{' '}
    <p className="formated-text">
      Printre simptomele funcționării slabe a părții executive din lobii
      prefrontali se numără neatenția, planificarea sau judecata slabă, timpul
      de reacție lent, lipsa conștientizării sociale și controlul slab al
      impulsurilor.
    </p>{' '}
    <p className="formated-text">
      Antrenamentul de neurofeedback HEG este o modalitate simplă și directă de
      a încetini degradarea cognitivă pe zona prefrontală. Este minim afectat de
      artefactul muscular și s-a dovedit deja a fi o formă eficientă de
      neurofeedback. Cu toate acestea, atunci când vine vorba de site-uri
      situate în regiunile cu păr ale scalpului (restul ariilor corticale:
      temporali, parietali, occipitali, cortexul motor) unde sunt probleme cu
      frecvențele specifice, dominația undelor rapide, coerența și asimetria,
      precum și nevoia de relaxare profundă, necesită EEG Neurofeedback, nu HEG
      Neurofeedback.
    </p>{' '}
    <p className="formated-text">
      <u> Măsuri de precauție:</u> Dacă un client este expus riscului de
      anevrism cerebral sau hemoragie, este necesar să consulte medicul neurolog
      înainte de a începe neuroterapia HEG.
    </p>{' '}
    <p className="formated-text">
      Printre simptomele care răspund la NIR-HEG amintim:
    </p>
    <ul style={{ margin: 0, padding: 0 }}>
      <li>
        Deficite de atenție (ADD), îmbunătățire cognitivă și procesare mentală
        mai rapidă{' '}
      </li>
      <li>Hiperactivitate (ADHD), agitație și impulsivitate </li>
      <li>Tulburări de atașament și întârzieri în dezvoltare </li>
      <li>
        Autism (TSA), tulburări de memorie (pentru îmbunătățirea memoriei){' '}
      </li>
      <li>Leziuni cerebrale </li>
      <li>Tulburări obsesiv – compulsive (TOC)</li>
      <li>Schizofrenie.</li>
    </ul>
  </div>,
  <div key="asd14wd" className="textsContainer">
    <h2>
      <strong>Ce este HEG Neurofeedback?</strong>
    </h2>
    <p className="formated-text">
      Antrenamentul HEG neurofeedback stimulezază activitatea fluxului de sânge
      către creier și prin urmare îmbunătățește funcționarea mentală și
      emoțională. Antrenamentul HEG alimentează schimbările din consumul de
      energie al creierului ( care este legat de fluxul de sânge, oxigen,
      nutrienți, aportul de glucoză). HEG are o rată ridicată de conformitate
      pentru clienți iar antrenarea este oferită cu ajutorul unui feedback
      distractiv și atractiv, potrivit atât pentru copii cât și pentru adulți.
    </p>
    <p className="formated-text">
      Câmpul hemoencefalografiei ( HEG ) studiază hemodinamica corticală:
      vascularitate, volum sanguin, oxigenare, metabolism sau temperatură în
      timp real. S-a constatat că variabilele vasculare, oxigenarea și fluxul de
      sânge sunt controlabile în mod voluntar. Controlul voluntar a dus la
      descoperiri suplimentare ale utilității HEG, o modalitate terapeutică ca
      modalitate în neurofeedback.
    </p>
    <p className="formated-text">
      Logica din spatele acestei intervenții neurofeedback este aceea că
      angajarea în mod repetat în biofeedback-ul HEG, „solicită” creierul
      într-un mod unic și conferă beneficii neuro-comportamentale foarte
      promițătoare și de lungă durată pentru client.
    </p>
    <p className="formated-text">
      Majoritatea cercetărilor în HEG s-au concentrat pe tulburările cortexului
      prefrontal, regiunea corticală din spatele frunții care controlează
      funcții executive cum ar fi: planificarea, judecata, reglarea emoțională,
      inhibarea, organizarea și determinarea cauzei și efectului.
    </p>
    <p className="formated-text">
      Funcționarea optimă a cortexului prefrontal este considerată esențială
      pentru toate comportamentele orientate spre obiective și mediate social.
      Persoanele cu ADHD au activitate prefrontală de undă lentă.
    </p>
    <p className="formated-text">
      Activarea prefrontală pare a fi necesară pentru a reuși tratamentul multor
      afecțiuni neurocomportamentale, cum ar fi ADD și ADHD.
    </p>
    <p className="formated-text">
      Hărțile topografice ale creierului QEEG și imaginile RMN funcționale ale
      clienților cu ADHD identifică lobii prefrontali ca fiind disfuncționali
      sau chiar „deconectați” de restul creierului prin{' '}
      <strong>prezența unei activități excesive de undă lentă</strong> în loc de
      o activitate de <u>undă rapidă dezirabilă</u>.
    </p>
    <h2>
      <strong>
        Ce ne spun nivelurile de oxigen despre activitatea creierului?
      </strong>
    </h2>
    <p className="formated-text">
      Când vine vorba de fluxul de sânge și oxigenul din creier, principiul este
      simplu: cu cât este mai activă o zonă, cu atât este mai mult sânge și
      oxigen.
    </p>
    <p className="formated-text">
      Neurofeedback face un pas înainte prin creșterea fluxului de sânge
      (perfuzie cerebrală) într-o zonă din creier (în acest caz - cortexul
      prefrontal care mediază concentrația și atenția) și prin asta face zona
      mai activă.
    </p>
    <p className="formated-text">
      Când o regiune a cortexului este utilizată într-o sarcină cognitivă
      specifică, activitatea neuronală din regiunea respectivă crește, crescând
      în consecință rata metabolică locală. Pentru a ține pasul cu cerințele
      nutriționale și de eliminare a deșeurilor cu o rată metabolică mai mare,
      fluxul de sânge cerebral către zona corticală utilizată trebuie să crească
      proporțional. Alături de creșterea debitului, moleculele de hemoglobină
      din sânge, care sunt responsabile pentru transportul și transferul
      oxigenului în țesut în întregul corp, trebuie să crească cantitatea de
      oxigen pe care o livrează în regiunea activată a cortexului, rezultând un
      nivel local mai mare de oxigenare a sângelui.
    </p>
    <h2>
      <strong>Aplicații clinice</strong>
    </h2>
    <p className="formated-text">
      HEG Neurofeedback crește fluxul de sânge al creierului în zona prefrontală
      a creierului și a arătat beneficii clinice semnificative, în special în
      zonele de migrenă, depresie și ADHD.
    </p>
    <p className="formated-text">
      Daca tot creierul este folosit continuu, consumul extraordinar de energie
      ar produce o conflagrație. Când cererea depășește aprovizionarea cu
      energie, creșterea creierului este condusă de angiogeneză. Creșterea
      numărul de capilare este un mod mai eficient de a furniza sânge în țesut
      decât presiunea crescută.
    </p>
    <p className="formated-text">
      Conform acestei teorii, o listă ă de tulburări învățate indică: probleme
      legate de vârstă, anxietate, insomnie, tulburări de excitare, dispoziție,
      durere, PTSD, relaxare, stări de conștiință, dependențe, focalizare
      deschisă, relații de viață și flexibilitate de atenție. O listă parțială
      similară tulburărilor de dezvoltare și vătămare în primul rând subliniază:
      autism, Asperger, dizabilitate de învățare, ADD / ADHD, leziuni cerebrale
      traumatice, pierderi de memorie, distractibilitate, Sindromul Tourette,
      schizofrenie, tulburarea bipolară, accidentul vascular cerebral și
      ischemia creierului.
    </p>
  </div>,
];
