import { Box, HStack, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import Hero from '../components/home/Hero';
import { Header, InfoSection } from '../components';
import Cards from '../components/Cards';
import { homeCards } from '../static/CardsData';

const Home = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'flex-start'}
      w={'full'}
      className="homeContainer"
      gap={0}
    >
      <Header text={'Ești ceea ce gândești. Fă-ți mintea să conteze!'} />
      <Hero />
      <InfoSection
        src="/images/neurofeed-w.webp"
        subTitle="Tehnică revoluționară"
        title="Neurofeedback IFL"
        text="Înainte de începerea terapiei propriu-zise are loc o evaluare a clientului apoi este stabilit protocolul personalizat care va fi urmat. Ședințele de neurofeedback asigură claritate, liniște și echilibru, aspecte foarte importante pentru reușita procesului terapeutic."
        buttonText="Descoperă Neurofeedback-ul"
        link="/neurofeedback/ce-este-neurofeedback-ul"
      />
      <Stack
        w={'full'}
        minH={'100vh'}
        bgImage={'/images/Bg1.webp'}
        bgRepeat={'no-repeat'}
        bgPos={'center'}
        bgSize={'cover'}
        position={'relative'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={'2rem'}
      >
        <Box
          width={'full'}
          h={'full'}
          position={'absolute'}
          zIndex={1}
          inset={'0 0 0 0'}
          bgColor={'rgba(0,0,0,0.5)'}
        ></Box>
        <Stack alignItems={'center'}>
          <Heading
            w={'fit-content'}
            zIndex={2}
            as={'h3'}
            fontSize={'1.5rem'}
            color={'secondOrange'}
          >
            Neurofeedback
          </Heading>
          <Heading
            w={{ base: '330px', md: '800px' }}
            zIndex={2}
            as={'h2'}
            fontSize={{ base: '2rem', md: '3rem' }}
            color={'textLight'}
            textAlign={'center'}
          >
            O metodă extrem de eficientă de optimizare a funcţiilor creierului
          </Heading>
        </Stack>
        <HStack
          zIndex={2}
          flexWrap={'wrap'}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems={'center'}
          maxW={'100rem'}
          w={'full'}
          gap={{ base: '1rem', md: 0 }}
        >
          {homeCards.map((item) => (
            <Cards
              link={item.link}
              src={item.src}
              alt={item.alt}
              title={item.title}
              description={item.description}
              button={item.button}
            />
          ))}
        </HStack>
      </Stack>
      <InfoSection
        src="/images/s2.svg"
        subTitle="Vrei o consultație?"
        title="Contactează-ne"
        text="Contactează unul din cabinetele noastre, pentru a stabili o consultație sau pentru a va lămuri în legatură cu tehnologia de Neurofeedback ILF."
        buttonText="Contactează-ne"
        link="/contact"
        flex={true}
      />
    </Stack>
  );
};

export default Home;
