import {
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <Stack
      w={'80%'}
      h={'100vh'}
      position={'fixed'}
      top={0}
      right={isOpen ? 0 : '-100%'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      gap={'3rem'}
      py={'1rem'}
      px={'3rem'}
      bg={'mainBlue'}
      color={'textLight'}
      transition={'all .8s ease-in-out'}
      zIndex={1000}
    >
      <Stack
        w={'full'}
        fontWeight={'bold'}
        letterSpacing={'2px'}
        fontSize={'1.2rem'}
        flex={1}
        gap={'3rem'}
        py={'3rem'}
      >
        <HStack
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Link to={'/'}>
            <Image
              w={'150px'}
              objectFit={'contain'}
              src={'/images/cerebrium-lw.png'}
              alt={'Auto Post'}
            />
          </Link>
          <CloseIcon cursor={'pointer'} onClick={toggle} />
        </HStack>
        <Stack
          alignItems={'flex-start'}
          spacing={'2rem'}
          fontWeight={'normal'}
          textTransform={'uppercase'}
        >
          <Link to={`/`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              acasă
            </Text>
          </Link>
          <Link to={`/despre`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              despre noi
            </Text>
          </Link>
          <Link to={`/brain-mapping`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              brain mapping
            </Text>
          </Link>

          <Menu>
            <MenuButton as={'Button'} rightIcon={<ChevronDownIcon />}>
              NEUROFEEDBACK
              <ChevronDownIcon />
            </MenuButton>
            <MenuList bg={'mainBlue'} border={'none'}>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/neurofeedback/ce-este-neurofeedback'}>
                  Ce este Neurofeedback-ul?
                </Link>
              </MenuItem>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/neurofeedback/afectiuni'}>
                  Ce afecțiuni se pot trata?
                </Link>
              </MenuItem>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/neurofeedback/frecventa-optima'}>
                  Ce este frecvența optimă?
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
          <Link to={`/heg`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              heg-nir
            </Text>
          </Link>
          <Menu>
            <MenuButton as={'Button'}>
              FOTOBIOMODULARE
              <ChevronDownIcon />
            </MenuButton>
            <MenuList bg={'mainBlue'} border={'none'}>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/fotobiomodulare/ce-este-fotobiomodularea'}>
                  Ce este Fotobiomodularea?
                </Link>
              </MenuItem>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/fotobiomodulare/recomandari'}>
                  Cât timp este recomandată?
                </Link>
              </MenuItem>
              <MenuItem
                bg={'mainBlue'}
                onClick={toggle}
                transition={'all ease-in-out .2s'}
                _hover={{
                  color: 'secondBlue',

                  borderBottom: 'medium solid #00b3ff',
                }}
              >
                <Link to={'/fotobiomodulare/utilizare-tsa'}>
                  Utilizarea în TSA
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
          <Link to={`/servicii`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              servicii
            </Text>
          </Link>
          <Link to={`/contact`}>
            <Text
              w={'fit-content'}
              onClick={toggle}
              transition={'border-bottom .2s ease-out'}
              _hover={{
                color: 'secondBlue',

                borderBottom: 'medium solid #00b3ff',
              }}
            >
              contact
            </Text>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
