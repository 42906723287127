import {
  HStack,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

export const echipaData = [
  {
    nume: 'Carp Ionela',
    titluri:
      'Psiholog clinician, Psihoterapeut integrativ, Terapeut neurofeedback',
    poza: '/images/ionela.jpg',
  },
  {
    nume: 'Trofim Iuliana',
    titluri: 'Psiholog clinician, Terapeut ABA, Terapeut neurofeedback',
    poza: '/images/iuliana.png',
  },
  {
    nume: 'Carp Andreea Simona',
    titluri:
      'Psiholog clinician, Terapeut ABA, Terapeut neurofeedback, Logoped',

    poza: '/images/andreea.jpg',
  },
];
export const serviciiData = [
  {
    link: '/brain-mapping',
    title: 'Brain Mapping',
    online: false,
  },
  {
    link: '/heg',
    title: 'HEG - NIR',
    online: false,
  },
  {
    link: '/neurofeedback/ce-este-neurofeedback-ul',
    title: 'Terapia neurofeedback',
    online: false,
  },
  {
    link: '/fotobiomodulare/ce-este-fotobiomodularea',
    title: 'Fotobiomodularea Transcraniană',
    online: false,
    locatie: 'Tulcea',
  },
  {
    _id: 7,
    link: '/serviciu/psihoterapie',
    slug: 'psihoterapie',
    title: 'Psihoterapia',
    online: false,
    text: [
      <div className="textsContainer">
        <HStack
          flexWrap={'wrap'}
          w={'full'}
          gap={'2rem'}
          justifyContent={'space-between'}
        >
          <Image
            w={'350px'}
            src={'/images/psihoterapie.png'}
            alt={'Cerebrium - Psihoterapie'}
          />
          <Text w={{ md: 'calc(100% - 400px)' }}>
            Psihoterapia este termenul care descrie procesul de tratare a
            suferinței psihice și tulburărilor psihologice prin tehnici verbale
            și psihologice testate. Este un proces de vindecare în care
            psihoterapeutul colaborează cu clientul cu scopul de a facilita
            starea de bine și a îmbunătăți calitatea vieții acestuia. Oricare ar
            fi conflictele interioare sau exterioare, psihoterapia poate fi
            benefică în îmbunatățirea calității vieții oricărui individ din
            lume. Unii au nevoie de sprijin pentru a face față unei boli grave,
            alții pentru a-și crește stima de sine sau pentru a renunța la o
            dependență.
          </Text>
        </HStack>
        <p>
          Potrivit Institutului Național de Sănătate Mintală, la nivel global,
          mai mult de un sfert dintre adulți experimentează simptome ale
          depresiei, anxietate sau se confruntă cu orice altă tulburare mintală
          într-un moment din viață.
        </p>
        <p>
          Psihoterapia poate ajuta la eliminarea sau controlul simptomelor
          tulburatoare, astfel încât o persoană să poată funcționa mai bine, să
          poată accelera procesul de vindecare și să-și îmbunătățească starea de
          bine generală.
        </p>
        <p>
          Astfel, putem afirma că psihoterapia este o modalitate de tratament
          cuprinzător, deliberat și planificat, prin mijloace și metodologii
          științifice, având un cadru clinic și teoretic, centrată pe reducerea
          sau eliminarea unor simptome, tulburări mintale sau stări de suferință
          psihosociale și/sau psihosomatice și comportamente disfuncționale.
        </p>
        <p>
          Scopul psihoterapiei este de a te face{' '}
          <strong>să te simți mai bine</strong>, pentru că modul în care te
          simți are un impact uriaș asupra{' '}
          <strong>calității vieții tale</strong>.{' '}
        </p>
        <p>
          Terapia te poate ajuta într-o gamă largă de probleme, de la
          nemulțumiri de moment la probleme în relații, până la traume profunde.
        </p>
        <p>
          Psihoterapia este un act de iubire pe care poți să ți-l oferi dacă
          simți că viața și relațiile tale nu reflectă ceea ce știi, în adâncul
          tău, că este posibil pentru tine.{' '}
        </p>
        <p>
          Apelând la psihoterapie îți poți recreea viața pe care ți-o dorești,
          poți ajunge să fii autentic și fericit, să-ți vindeci traumele și să
          renunți la tiparele comportamentale care nu îți mai sunt de folos.
        </p>
        <p>
          Abordarea psihoterapiei integrative în care sunt formată presupune o
          metodă centrată pe relația terapeutică, care îmbină partea cognitivă
          cu cea emoțională și corporală respectând unicitatea și complexitatea
          ta, ca om.
        </p>
      </div>,
    ],
  },
  {
    _id: 8,
    link: '/serviciu/logopedie',
    slug: 'logopedie',
    title: 'Logopedia',
    online: false,
    text: [
      <div className="textsContainer">
        <p>
          <strong>Logopedia</strong> se concentrează pe îmbunătățirea
          abilităților de vorbire, limbaj, înțelegere a limbajului, scriere și
          alte aspecte legate de comunicare.logopedia este o disciplină
          esențială pentru îmbunătățirea abilităților de comunicare și pentru
          tratamentul tulburărilor de comunicare. Prin intermediul logopediei,
          indivizii pot dobândi sau recâștiga abilitățile necesare pentru a se
          exprima eficient în societate și pentru a îmbunătăți calitatea vieții
          lor. Logopedia este utilă din mai multe motive importante:
        </p>
        <ul>
          <h3 className="subtitle">Îmbunătățirea Comunicării: </h3>
          <li>
            Logopedia ajută persoanele cu tulburări de comunicare să dezvolte
            abilități de vorbire, limbaj și comunicare mai eficiente. Aceasta
            poate include corectarea pronunției, dezvoltarea vocabularului,
            înțelegerea și folosirea corectă a regulilor gramaticale și
            dezvoltarea abilităților de conversație.
          </li>
          <h3 className="subtitle">
            Sprijin pentru Copiii cu Dificultăți de Limbaj:{' '}
          </h3>
          <li>
            Pentru copiii cu întârzieri de limbaj sau tulburări de vorbire,
            logopedia poate fi esențială în asigurarea unei dezvoltări
            lingvistice adecvate. Aceasta poate avea un impact semnificativ
            asupra succesului lor în școală și în viața de zi cu zi.
          </li>
          <h3 className="subtitle">Tratamentul Tulburărilor de Limbaj: </h3>
          <li>
            Logopezii lucrează cu persoanele care suferă de tulburări de limbaj,
            cum ar fi afazia (pierderea abilității de a vorbi sau de a înțelege
            vorbirea). Terapia logopedică poate ajuta la recuperarea sau
            ameliorarea acestor tulburări.
          </li>
          <h3 className="subtitle">
            Sprijin pentru Copiii cu Tulburări de Dezvoltare:{' '}
          </h3>
          <li>
            Logopedia poate ajuta copiii cu autism și alte tulburări de
            dezvoltare să dezvolte abilități de comunicare socială și limbaj,
            ceea ce le poate îmbunătăți calitatea vieții și interacțiunea cu
            alți oameni.{' '}
          </li>
          <h3 className="subtitle">
            Reabilitarea Post-Accident Vascular Cerebral (AVC):{' '}
          </h3>
          <li>
            Pentru persoanele care au suferit un AVC și au pierdut abilitățile
            de comunicare, logopedia este esențială în procesul de recuperare și
            reabilitare a limbajului.
          </li>
          <h3 className="subtitle">Îmbunătățirea Abilităților de Scriere: </h3>
          <li>
            Logopedia se ocupă și de tulburările de scriere, ajutând persoanele
            să înțeleagă și să utilizeze corect ortografia, gramatica și
            structura propozițiilor.
          </li>
        </ul>
      </div>,
    ],
  },
  {
    _id: 0,
    link: '/serviciu/consiliere-psihologica',
    slug: 'consiliere-psihologica',
    title: 'Consilierea psihologică',
    online: true,
    text: [
      <div className="textsContainer">
        <p>
          <b>Consilierea psihologică</b> este o intervenție psihologică în
          scopul optimizării, autocunoașterii și dezvoltării personale și / sau
          în scopul promovării sănătății, prevenției și remiterii problemelor
          emoționale, cognitive și de comportament, ce se adresează problemelor
          pe care le poate avea o persoană în plan emoțional, profesional,
          social și chiar fizic. <b>Consilierea psihologică</b> poate fi
          individuală, de grup (cuplu, familie) sau în grup.
        </p>
        <p>
          Psihologul ajută oamenii să depășească aceste dificultăți și să-și
          îmbunătățească starea de bine, să-și diminueze trăirile negative și să
          rezolve crizele din viața lor.{' '}
        </p>
        <p>
          Poți să discuți cu un psiholog legat de diverse aspecte din viața ta,
          care simți că nu funcționează așa cum ar trebui. Acest lucru este
          diferit de a discuta cu un membru al familiei sau un prieten pentru că
          un psiholog este un specialist care are o privire obiectivă și care te
          poate ajuta să găsești soluțiile optime pentru tine, care pot produce
          cu adevărat schimbarea pe care ți-o dorești.
        </p>
        <p>
          Psihologul ia în calcul elemente precum etapa de viață în care se află
          persoana care se prezintă la cabinet (copilărie, adolescență, vârstă
          adultă sau maturitate târzie), factori de mediu (stilul de viață,
          elementele sociale și culturale care au un impact asupra vieții
          persoanei respective), aspectele din plan profesional, precum și cele
          individuale (atuuri, abilități, puncte forte).
        </p>
        <p>
          <b>Consilierea psihologică</b> ia în calcul modul în care oamenii
          relaționează cu ceilalți, cum gândesc și cum se comportă, cum văd ei
          lumea și cum funcționează în viața de zi cu zi. Aceasta include
          explorarea aspectelor sociale, economice, culturale, spirituale și
          cele legate de sănătatea fizică. Scopul este de a reduce disconfortul
          psihologic și de a promova starea de bine interioară a unei persoane.
        </p>
        <p>
          <b>Consilierea vocațională</b> poate ajuta o persoană să își aleagă o
          carieră mai bună sau mai potrivită, poate contribui la recunoașterea,
          punerea în valoare și utilizarea abilităților de care ea dispune și îi
          poate îmbunătăți stima de sine, astfel încât procesul de căutare a
          unei cariere și de participare la interviurile de angajare să se
          finalizeze cu succes. Orientarea în carieră te poate ajuta să găsești
          domeniul adecvat pentru tine, care să îți permită să te simți împlinit
          și satisfăcut pe plan profesional.
        </p>{' '}
      </div>,
    ],
  },

  {
    _id: 1,
    link: '/serviciu/dezvoltare-personala',
    slug: 'dezvoltare-personala',
    title: 'Dezvoltarea personală',
    online: true,
    text: [
      <div className="textsContainer">
        <p>
          Nu trebuie să ai anumite probleme pentru a beneficia de serviciile
          unui psiholog. Poți apela la acesta pentru optimizare, dezvoltare
          personală și autocunoaștere.
        </p>
        <ul>
          <h3 className="subtitle">În acest fel: </h3>
          <li>
            vei fi ajutat să te cunoști mai bine, să-ți gestionezi emoțiile și
            sentimentele astfel încât relațiile cu cei din jur să fie
            armonioase,
          </li>
          <li>
            vei înțelege mai bine ce se întâmplă cu tine și cu cei din jurul tău
          </li>
          <li>
            vei învăța să fii o persoană asertivă atât în viața personală cât și
            în cea profesională,
          </li>
          <li>vei avea o stimă de sine mai bună, </li>
          <li>
            vei învăța să te apreciezi mai mult și să gestionezi mai bine
            anumite situații.
          </li>
        </ul>
        <p>
          <b>Dezvoltarea personală</b> este un concept de confluență, aflat la
          intersecția unor elemente din psihologie cum ar fi: motivația,
          autocunoașterea, managementul emoțiilor, stima de sine, atitudinea
          (pozitivă) și lista rămâne deschisă. Adițional, este susținută de
          discipline cum ar fi: filosofie, economie, politică, medicină sau
          sport.
        </p>
        <p>
          Psihologia, prin instrumentele și tehnicile de terapie dezvoltate pe
          parcursul timpului, are un aport nu numai în identificarea
          patologiilor și tratamentelor necesare, dar și în viața de zi cu zi,
          prin scoaterea la suprafață și înțelegerea experiențelor trăite de un
          individ și impactul lor asupra imaginii de sine și de viață pe care și
          le-a creat.
        </p>
        <p>
          În schimb,{' '}
          <b>
            <i>dezvoltarea personală</i>
          </b>{' '}
          se concentrează pe un singur aspect al experiențelor proprii, mai
          exact pe{' '}
          <b>
            <i>eficientizarea </i>
          </b>{' '}
          acestora și{' '}
          <b>
            <i>dezvoltarea individului</i>
          </b>{' '}
          în sens de câștig financiar, profesional sau de statut social. Astfel,
          dezvoltarea personală scoate din ecuație instrumentele care stau la
          baza terapiei psihologice, excavarea traumelor și experiențelor
          trecute și se concentrează pe conturarea unui stil de viață, centrat
          pe zona exterioară, care să-i aducă individului satisfacții.
        </p>
        <p>
          <b>Dezvoltarea personală</b> este un proces de lungă durată,
          individual sau împreună cu un psiholog sau terapeut și are ca scop
          creșterea eficacității atât în plan profesional (a fi mai bun, în
          termeni de competitivitate și performanță, prin optimizarea
          abilităților personale), dar și pentru a îmbunătății calitatea vieții,
          a stării de bine și a relațiilor interpersonale.
        </p>
        <p>
          Într-o lume care se află într-un proces alert de schimbare,
          dezvoltarea personală este un proces transformativ de maturizare
          continuă, care își propune valorificarea potențialului individual în
          cea mai bună versiune posibilă sau altfel spus, este un demers prin
          care o persoană se valorifică pe sine la cel mai înalt nivel.
        </p>
      </div>,
    ],
  },
  {
    _id: 2,
    link: '/serviciu/evaluare-testare-psihologica',
    slug: 'evaluare-testare-psihologica',
    title: 'Evaluarea și Testarea psihologică',
    online: false,
    text: [
      <div className="textsContainer">
        <p>
          <b>Evaluarea psihologică</b> urmărește investigarea comportamentelor
          și a funcțiilor psihice ale individului, evidențierea eventualelor
          tulburări psihice, dar și modalitățile de funcționare deficitare ce se
          pot îmbunătăți semnificativ prin psihoterapie.
        </p>
        <p>
          Prin diagnostic și evaluare psihologică înțelegem investigarea și
          psihodiagnosticul tulburarilor psihice dar și a altor condiții de
          patologie în care sunt implicate mecanisme psihologice.
        </p>
        <ul>
          <h3>
            <b>Evaluarea psihologică</b> poate cuprinde:
          </h3>
          <li>evaluarea stării de sănătate mintală;</li>
          <li>evaluarea cognitivă și neuropsihologică;</li>
          <li>evaluarea comportamentală;</li>
          <li>evaluarea emoționala (afectivă);</li>
          <li>
            evaluarea personalității și a mecanismelor de adaptare/apărare;
          </li>
          <li>
            evaluarea anumitor aspecte psihologice specifice cuplului și
            familiei;
          </li>
          <li>evaluarea gradului de discernământ;</li>
          <li>evaluarea dezvoltării psihologice.</li>
        </ul>
        <p>
          Aceasta se desfașoară sub forma unei discuții, psihologul urmărește
          aflarea datelor biografice ale persoanei, identificarea unor situații
          sau evenimente (accidente, traume, abuzuri) care ar fi putut produce
          efecte în timp, identificarea unor simptome, identificarea unui
          istoric de viață. De asemenea psihologul clinician poate decide
          necesitatea includerii în procesul de evaluare și a unei serii de
          teste psihometrice. Numărul de ședințe diferă în funcție de evaluarea
          solicitată.
        </p>
        <h2 className="title">Evaluare psihologică la adulți</h2>
        <p>
          În cazul evaluării adulților accentul este pus pe clarificarea zonelor
          cu probleme, identificarea resurselor și abilităților existente,
          dezvoltarea unor obiective realiste și pe furnizarea unui tratament
          personalizat. Întreaga evaluare psihologică rezultă din analiza
          datelor biografice, testelor psihologice, interviului clinic.
        </p>
        <h2 className="title">Evaluare psihologică la copii și adolescenți</h2>
        <p>
          Din proprie iniţiativă sau la recomandarea unui medic care are nevoie
          de profilul psihologic al copilului, se întâmplă ca părinţii să ajungă
          cu copiii lor, la diferite vârste, în cabinetul unui psiholog.
        </p>
        <p>
          Înainte de a duce copilul la evaluarea propriu-zisă este de preferat
          ca părinţii să discute separat cu psihologul pentru a-i furniza
          acestuia informații suplimentare.
        </p>
        <p>
          Evaluarea clinică trebuie de asemenea precedată de o pregătire sumară
          a copilului pentru şedinţele prin care va trece şi de o încurajare în
          ideea obţinerii de către psiholog a unor răspunsuri sincere, cu
          asigurarea că, indiferent de răspunsuri, şedinţele nu vor avea
          consecinţe nedorite pentru copil. Abia ulterior începe evaluarea.
        </p>
        <p>
          Scopul evaluarii psihologice este cunoașterea în ansamblu a copilului
          sau adolescentului pentru a găsi programe adecvate care să-i stimuleze
          dezvoltarea.{' '}
        </p>
        <p>
          Este foarte important pentru parinți să-și poată adapta comportamentul
          și atitudinea în funcție de cerințele copilului și să-i înțeleagă
          nevoile la un nivel profund.
        </p>
        <p>
          Evaluarea psihologică are ca scop cunoașterea caracteristicilor
          personalității fiecărui copil și adolescent, fiind vizate ariile de
          dezvoltare motorie, senzorială, cognitivă, afectiv-motivațională,
          socio-relațională și de autonomie personală.
        </p>
        <ul>
          <h3>Evaluarea are două obiective: </h3>
          <li className="roman">
            de a furniza un profil psihologic plecând de la evaluarea
            următoarelor dimensiuni: nivel cognitiv (QI), semne şi simptome ale
            principalelor tulburări psihice (depresie, anxietate, ADHD – deficit
            de atenţie / hiperactivitate, tendinţe obsesiv-compulsive, probleme
            de conduită, comportamente disruptive etc.), patternuri emergente de
            personalitate, competenţe sociale şi academice, strategii de coping.
          </li>
          <li className="roman">
            de a furniza asistenţă de specialitate pentru a ȋnţelege cât mai
            bine modul de funcţionare a copilului sau a adolescentului ȋn plan
            psihologic şi comportamental.
          </li>
        </ul>
        <p>
          În funcţie de motivele pentru care este solicitată consultaţia
          psihologică, specialistul recurge la anumite instrumente şi proceduri.
          Procesul de evaluare se derulează, de obicei, pe durata a două - trei
          şedinţe de aproape o oră, organizate sub formă de: interviu clinic,
          administrare teste, concluzii şi recomandări de specialitate.
        </p>
        <p>
          Ȋn procesul de evaluare sunt utilizate instrumente psihologice
          standardizate şi validate ştiinţific adaptate cultural şi normate pe
          populaţia din România care oferă o cunoaştere validă a factorilor
          psihologici care contribuie la starea de sănătate mintală şi
          funcţionarea adaptativă.
        </p>
        <p>
          Ȋn urma evaluării se formulează un psihodiagnostic ȋn cazul
          problemelor de sănătate mintală, se identifică ariile problematice,
          vulnerabilităţi şi factorii de risc, se pun bazele privind pregătirea
          unor programe particularizate de intervenţie.
        </p>
        <p>
          <b>
            Se poate realiza pentru toate categoriile de vârsta, folosind
            instrumente specifice adecvate fiecărui copil în parte.
          </b>
        </p>
      </div>,
    ],
  },

  {
    _id: 3,
    link: '/serviciu/terapie-aba',
    slug: 'terapie-aba',
    title: 'Terapia ABA - Analiză Aplicată a Comportamentului',
    online: false,
    text: [
      <div className="textsContainer">
        <p>
          Analiza comportamentală aplicată este o știință dedicată înțelegerii
          și îmbunătățirii comportamentului uman. ABA este o știință dedicată
          dezvoltării de proceduri care vor produce schimbări observabile în
          comportament.
        </p>
        <p>
          Pe scurt ABA este o abordare științifică pentru a descoperi
          variabilele de mediu care influențează în mod real comportamente
          semnificative social și pentru dezvoltarea unor intervenții de
          schimbare a comportamentelor care să profite în mod practic de aceste
          descoperiri. Abordarea caută adesea să dezvolte alternative
          acceptabile din punct de vedere social pentru comportamentele
          anormale.
        </p>
        <p>
          În timp ce ABA pare să fie intrinsec legată de intervenția în autism,
          ea este utilizată și într-o gamă largă de alte situații.{' '}
        </p>
        <p>
          Domeniile recente notabile de cercetare în cadrul ABA includ:
          autismul, predarea la clasă elevilor tipici, terapia de alimentație
          pediatrică și tulburările legate de consumul de substanțe.
        </p>
        <p>
          Alte aplicații ale ABA includ analiza comportamentului consumatorului,
          analiza comportamentală clinică, analiza comportamentală
          medico-legală, creșterea siguranței și a performanței la locul de
          muncă, sprijinul comportamental pozitiv la școală și desensibilizarea
          sistematica progresivă pentru fobii.
        </p>
        <p>
          <b>ABA (Analiza Aplicată a Comportamentului)</b> este o tehnică
          folosită des în tratamentul copiilor diagnosticați cu autism. Metodele
          de analiză a comportamentului au fost folosite și studiate timp de
          decenii. Ele au ajutat multe tipuri de persoane să dobândească
          diferite abilități - de la un stil de viață mai sănătos la învățarea
          unei limbi noi. Terapeuții au folosit ABA pentru a ajuta copiii cu
          autism și tulburări de dezvoltare înrudite încă din anii 1960.
        </p>
        <p>
          ABA este considerat cel mai bun tratament bazat pe dovezi de către US
          Surgeon General și de către Asociația Psihologică Americană. "Bazat pe
          dovezi" înseamnă că ABA a trecut testele științifice privind
          utilitatea, calitatea și eficacitatea sa. Terapia ABA include multe
          tehnici diferite. Toate aceste tehnici se concentrează pe antecedente
          (ceea ce se întâmplă înainte de apariția unui comportament) și pe
          consecințe (ceea ce se întâmplă după comportament).
        </p>
        <p>
          Mai mult de 20 de studii au stabilit că terapia intensivă și pe termen
          lung care utilizează principiile ABA îmbunătățește rezultatele pentru
          mulți copii cu autism, dar nu pentru toți. Aceste studii arată
          îmbunătățiri în ceea ce privește dezvoltarea intelectuală, dezvoltarea
          limbajului, abilitățile de viață zilnică și funcționarea socială.
        </p>
        <h2 className="title">ABA in autism</h2>
        <ul>
          <h3>
            Autismul este abordat din prisma comportamentelor observabile
            manifestate de persoană și acestea sunt imparțite în două categorii:
          </h3>{' '}
          <ul>
            <h3 className="subtitle">1. Comportamente care apar în exces:</h3>
            <li>Comportamente stereotipe;</li>
            <li>Comportamente auto/hetero-agresive;</li>
            <li>Obsesii;</li>
            <li>Tantrums.</li>
          </ul>
          <ul>
            <h3 className="subtitle">
              2. Comportamente care apar foarte rar (deficite):
            </h3>
            <li>Limbaj;</li>
            <li>Abilitați sociale;</li>
            <li>Abilitați de joc;</li>
            <li>Abilitați academice;</li>
            <li>Abilitați de autoservire.</li>
          </ul>
        </ul>
        <ul>
          <h3 className="subtitle">ABA poate ajuta copiii cu autism în:</h3>
          <li>Creșterea comportamentelor dezirabile.</li>
          <li>Creșterea competențelor lingvistice și de comunicare.</li>
          <li>Predarea noilor abilități.</li>
          <li>Îmbrăcatul şi dezbrăcatul corespunzător.</li>
          <li>Autoservirea și independența.</li>
          <li>Menținerea unui comportament (învățarea autocontrolului).</li>
          <li>
            Îmbunătățirea atenției, a concentrării, a abilităților sociale, a
            memoriei și a rezultatelor școlare.
          </li>
          <li>Generalizarea sau transferul comportamentelor învățate.</li>
          <li>
            Restrângerea factorilor de mediu care acționează asupra copilului
            când învață.
          </li>
          <li>Ameliorarea problemelor de motricitate fină şi grosieră</li>
          <li>
            Reducerea comportamentelor nedorite (agresivitatea,
            auto-agresivitatea, stereotipii, auto-stimulări).
          </li>
        </ul>
        <h2 className="title">Antecedent – Comportament - Consecință</h2>
        <p>
          Înțelegerea antecedentelor (ce se întâmplă înainte de apariția unui
          comportament) și a consecințelor (ce se întâmplă după comportament)
          este o altă parte importantă a oricărui program ABA.
        </p>
        <ul>
          <h3>Următoarele concepte ne ajută să înțelegem comportamentul:</h3>
          <li>
            <b>Antecedent:</b> acesta este ceea ce se întâmplă chiar înainte de
            comportamentul țintă. Acesta poate fi verbal, cum ar fi o comandă
            sau o cerere. Poate fi, de asemenea, fizic, cum ar fi o jucărie sau
            un obiect, o lumină, un sunet sau altceva în mediul înconjurător. Un
            antecedent poate proveni din mediul înconjurător, de la o altă
            persoană sau poate fi intern (cum ar fi un gând sau un sentiment).
          </li>
          <li>
            <b>Comportament (rezultat):</b> acesta este răspunsul sau lipsa de
            răspuns al persoanei la antecedent. Acesta poate fi o acțiune, un
            răspuns verbal sau altceva.
          </li>
          <li>
            <b>Consecință:</b> aceasta este ceea ce vine imediat după
            comportament. Aceasta poate include întărirea pozitivă a
            comportamentului dorit sau lipsa ei pentru răspunsurile
            incorecte/inadecvate.
          </li>
        </ul>
        <h2 className="title">Pentru TSA (Tulburare de spectru autist):</h2>
        <p>
          ABA este un program de învățare, care își propune să modifice
          comportamentul copilului cu obiectivul final de a-i îmbunătăți
          abilităților. Sistemul are obiective clar formulate, împărțite în
          sarcini mici, astfel încât evoluția copilului să poată fi măsurată
          permanent. Întărirea comportamentului se face cu recompense, întreg
          sistemul funcționând pe baza acestor întăritori fie materiali, fie
          sociali.
        </p>
        <p>
          ABA se bazează pe observarea comportamentului, recompensare pozitivă
          și prompt. Comportamentul unui copil este întărit atunci când
          răspunsul este corect. Comportamentele nedorite sau cele care
          interferează cu învățarea abilităților sociale sunt observate cu
          atenție. Scopul este determinarea funcției comportamentul respectiv,
          precum și observarea a ceea ce se întâmplă după manifestarea acelui
          comportament, ce anume întărește comportamentul respectiv.
        </p>
        <p>
          Terapeutul măsoară progresul prin colectarea de date în fiecare
          ședință de terapie. Datele îl ajută să monitorizeze în permanență
          progresul copilului pentru a ajusta terapia potrivit obiectivelor.
        </p>
        <h2 className="title">Avantajele terapiei ABA</h2>
        <p>
          Această terapie este esențial să se înceapă de la o vârstă mică, iar
          părinții trebuie să se implice în programul de recuperare și de
          asemenea să se urmeze corect indicațiile și sarcinile pentru acasă
          primite în terapie. Programul se face individual în funcție de
          abilitățile copilului, deoarece deși au același diagnostic, necesită o
          intervenție specială și individualizată.
        </p>
      </div>,
    ],
  },
  {
    _id: 4,
    link: '/serviciu/terapie-suportiva',
    slug: 'terapie-suportiva',
    title: 'Terapia suportivă',
    online: true,
    text: [
      <div className="textsContainer">
        <p>
          <b>Terapia suportivă</b> este o colecție de metode sau tehnici
          utilizate pentru a efectua un tratament psihologic, având un set
          relevant de obiective, între o persoană instruită profesional,
          psihologul și beneficiarul terapiei (client, pacient) și reprezintă un
          mijloc prin care i se oferă individului suport și empatie. Această
          terapie face pacientul să se simtă mai sigur, mai acceptat și
          încurajat.
        </p>
        <p>
          Ascultarea este prima și cea mai importantă componentă a terapiei
          suportive. Clientul este încurajat să vorbească despre problemele lui
          cât mai mult posibil, el simte că este ascultat și că grijile și
          problemele lui sunt luate cât mai în serios.
        </p>
        <ul>
          <h3>
            Printre obiectivele cele mai des menționate în literatura de
            specialitate amintim:
          </h3>
          <li>Reducerea unor comportamente disfuncționale</li>
          <li>Analiza și îmbunătățirea unor moduri de gândire distorsionate</li>
          <li>Maximizarea autonomiei tratamentului</li>
          <li>Restructurarea personalității</li>
          <li>
            Obținerea unei independențe și stări funcționale maxime în cazul
            tulburărilor psihice
          </li>
          <li>
            Rezolvarea crizelor acute (în doliu, traume, dezastre, tentativă de
            sinucidere sau crize asociate cu boli medicale grave sau cornice)
          </li>
          <li>Promovarea aderării la planurile de tratament</li>
          <li>Scăderea comportamentelor inadecvate</li>
          {/* ----------- */}
          <li>
            Scăderea proceselor conținutului tiparelor de gândire distorsionate
          </li>
          <li>
            Îmbunătățirea abilităților de a face față evenimentelor negative
          </li>
          <li>Îmbunătățirea abilităților sociale</li>
          <li>Rezolvarea conflictelor externe</li>
          <li>
            Prevenirea recidivelor, deteriorării unor tulburări psihice sau
            internărilor cauzate de acestea
          </li>
          <li>Sporirea stimei de sine</li>
          <li>
            Îmbunătățirea percepției realității (a pacientului, a altora și a
            lumii)
          </li>
          <li>Consolidarea unor moduri de apărare sănătoase, adaptive</li>
          <li>Diminuarea sau rezolvarea metodelor defensive dezadaptative</li>
          <li>Maximizarea sprijinului familial și social</li>
        </ul>
        <p>
          <b>Terapia suportivă</b> este aplicată de catre psiholog și urmărește
          o întărire a mecanismului de apărare, refacerea mecanismului de
          adaptare, educarea și o permanentă încurajare a pacientului și de
          asemenea favorizarea descărcării emoționale care duce în final la
          scăderea anxietății.
        </p>
        <ul>
          <h3>Scopul terapiei suportive este:</h3>
          <li>
            redobândirea abilităților sociale (relaționare interumană mai
            ușoară)
          </li>
          <li>dobândirea unui sentiment de participare la viața colectivă</li>
          <li>
            înțelegerea situației pe care o traversează, mobilizându-și forțele
            adaptative, într-o atmosferă de acceptare și securitate.
          </li>
        </ul>
        <ul>
          <h3>
            <b>Terapiea suportivă</b> ajută în rezolvarea unei game largi de
            probleme:
          </h3>
          <li>
            probleme personale: stres, anxietate, atacuri de panică, nevroze,
            depresii, tentative de suicid, fobii, obsesii, insomnii, dependențe
            (alcool, medicamente, droguri, jocuri de noroc), timiditate,
            echilibru emoțional, de dinamică sexuală, de comunicare, de vorbire
            (balbism), ticuri, furie, agresivitate, coșmaruri, migrene, vertij,
            palpitații, dermatologice (erupții, eczeme);
          </li>
          <li>
            probleme în relație: cuplu, copii, parinti, prieteni, șefi, colegi,
            stări conflictuale;
          </li>
          <li>
            dificultati emoționale și de relaționare la copii și adolescenți;
          </li>
        </ul>
      </div>,
    ],
  },
  {
    _id: 5,
    link: '/serviciu/servicii-online',
    slug: 'servicii-online',
    title: 'Servicii online',
    online: true,
    text: [
      <div className="textsContainer">
        <p>
          <b>Serviciile psihologice on-line</b> se adresează persoanelor care au
          împlinit vârsta de 18 ani și care din diferite motive, nu vor sau nu
          pot să aibă o întâlnire față în față cu psihologul.
        </p>
        <p>
          Serviciile on-line (consiliere, dezvoltare personală, terapie
          suportivă) sunt servicii psihologice asemănătoare celor din cabinet,
          cu diferența că întâlnirea cu psihologul este mediată de calculator,
          implicit internet.
        </p>
        <ul>
          <h3>
            Consilierea psihologică, dezvoltarea personală și terapia suportivă
            practicate on-line nu înlocuiesc evaluarea psihologică, însă au o
            serie de avantaje cum ar fi:
          </h3>
          <li>flexibilitatea,</li>
          <li>
            pot fi folosite în orice moment și în orice loc ( birou, concediu,
            week-end, acasă).
          </li>
        </ul>
        <h2 className="warning">
          Sunt anumite probleme care nu pot fi rezolvate prin on-line!
        </h2>
      </div>,
    ],
  },

  {
    _id: 6,
    link: '/serviciu/coaching',
    slug: 'coaching',
    title: 'Coaching',
    online: true,
    text: [
      <div className="textsContainer">
        <p>
          <strong>
            <em>
              “Coachingul este despre a ajuta oamenii să identifice, să
              valorifice învățarea, care este chiar dincolo de capacitățile lor
              actuale, dar la îndemâna lor și necesare pentru următorul pas
              înainte.”
            </em>
          </strong>{' '}
          - David Drake, Fondator al coachingului narativ
        </p>
        <h2>Ce este coachingul?‍</h2>
        <p>
          <strong>Coaching-ul</strong> este procesul prin care ești ghidat spre
          a atinge fericirea sau performanța în viața ta, folosind resursele pe
          care le ai la dispoziție, dobândind noi resurse, urmând un plan și
          păstrându-ți focusul.
        </p>
        <p>
          Este un instrument care are ca scop îmbunătățirea performanței,
          concentrându-se mai degrabă pe <em>“aici și acum”</em>, decât pe
          trecutul sau viitorul îndepărtat.
        </p>
        <p>
          Există o diferență între a învăța pe cineva și a-l ajuta să învețe.
          Coachul este un facilitator al învățării, care ajută clientul să-și
          îmbunătățească propria performanță.
        </p>

        <h2>Cum se derulează coaching-ul?</h2>
        <p>
          Fie că este vorba de întâlniri în format fizic sau on-line,
          coaching-ul este compus din sesiuni. De regula, sesiunile sunt
          săptămânale sau la un alt interval de timp stabilit de la început.
          Totul depinde de obiectivul pe care dorești să îl atingi și dacă după
          ce îl atingi dorești să te oprești sau să mergi mai departe.
        </p>
        <h2>Ce face un Coach?</h2>
        <p>
          Un coach este aici pentru a te ajuta să realizezi obiectivele pe care
          tu le stabilești. Ca parte a procesului, acesta îți adresează
          întrebări care stimulează conversația și introspecția și te ajută să
          vezi lucrurile dintr-un punct de vedere diferit.
        </p>
        <p>
          Un coach îi ajută pe clienții săi să se cunoască pe sine fără a le
          impune ceva anume și construiește un parteneriat care-i va ajuta să
          obțină cele mai bune rezultate pe plan personal sau profesional, mai
          exact să sporească calitatea vieților lor. Acesta este format să
          asculte, să observe și să adapteze subiectele în concordanță cu
          nevoile și obiectivele clientului.
        </p>
        <p>
          Rolul coach-ului este acela de a oferi suportul necesar clientului
          pentru a-și dezvolta abilitățile, resursele și creativitatea pe care
          deja le posedă.{' '}
        </p>
        <div className="rowUl">
          <ul>
            <h3>
              <strong>Cele 4 elemente de bază ale coaching-ului</strong> sunt:{' '}
            </h3>
            <li>
              <strong>
                <em>Schimbarea:</em>
              </strong>{' '}
              implică <strong>gândire, direcție, învățare și acțiune </strong>
            </li>
            <li>
              <strong>
                <em>Obiectivele: </em>concentrarea pe modalitatea de atingere a
                țelurilor
              </strong>
            </li>
            <li>
              <strong>
                <em>Valorile</em>
              </strong>{' '}
              și{' '}
              <strong>
                <em>Credințele:</em>
              </strong>{' '}
              (re)cunoașterea a ce este important pentru fiecare client, a
              propriilor criterii și resurse care pot fi accesate pentru
              atingerea obiectivelor; aspirații și atitudini pentru a depăși
              convingerile care (auto)limitează{' '}
            </li>
            <li>
              <strong>
                <em>Relația de parteneriat</em>
              </strong>{' '}
              dintre coach și client{' '}
            </li>
          </ul>
          <ul>
            <h3>
              <strong>Ce beneficii îți aduce coachingul?</strong>
            </h3>
            <li>
              Îți oferă un spațiu sigur pentru a vorbi despre provocările și
              gândurile cele mai profunde
            </li>
            <li>Facilitează formularea obiectivelor SMART</li>
            <li>
              Îți oferă o prezență continuă, pe termen scurt sau lung, pentru
              a-ți urmări și sprijini evoluția
            </li>
            <li>
              Te ajută să-ți pui sub semnul întrebării percepțiile și credințele
              limitative
            </li>
            <li>
              Îți permite să devii conștient de propriile calități, prin
              creșterea încrederii în sine
            </li>
            <li>
              Te determină să devii responsabil pentru acțiunile tale și să faci
              primii pași pentru atingerea potențialului și obiectivelor
              propuse.
            </li>
          </ul>
        </div>
        <h2>Când apelăm la coaching?</h2>
        <ul>
          <h3>
            Subiectele abordate sunt diverse. Orice arie a vieții poate deveni
            subiectul unui proces de coaching. Iată doar câteva exemple:
          </h3>
          <li>
            <strong>Blocajul.</strong> Cel mai des întâlnit motiv pentru care se
            apelează la coaching. Coachul te provoacă să-ți depășești tiparele
            de gândire și limitele autoimpuse, te scoate din zona de confort, te
            ajută să-ți lărgești perspectivele și să găsești soluții originale.
          </li>
          <li>
            <strong>
              Stabilirea unui plan de acțiune și punerea lui în practică.
            </strong>{' '}
            Aici coachul te sprijină să îți clarifici opțiunile sau să generezi
            altele noi și să alegi singur planul de acțiune cel mai potrivit
            pentru tine, în acel moment.
          </li>
          <li>
            <strong>
              Identificarea celui mai potrivit traseu profesional.
            </strong>{' '}
            Folosește coachingul ca instrument pentru a identifica domeniul care
            să-ți aducă satisfacții profesionale și a determina modalitățile
            prin care poți găsi sau crea jobul ideal.
          </li>
        </ul>
        <p>
          Alte aspecte des tratate în ședințele de coaching ar mai fi:
          managementul eficient al timpului, eliberarea de frustrare și stres,
          stimularea creativității, îmbunătățirea relațiilor interpersonale,
          etc.
        </p>
        <p>
          Coaching-ul este centrat cu precădere pe prezent şi pe viitor, pe
          procesul de schimbare, care se bazează pe luarea micilor decizii, mai
          mult decât a marilor decizii.
        </p>
        <ul>
          <h3>
            Coach-ul este focusat pe proces, fiind cel care îndrumă clientul
            pentru:
          </h3>
          <li>a conștientiza starea actuală</li>
          <li>a găsi opțiunile și alternativele pentru schimbare</li>
          <li>
            a persevera și persista în procesul de schimbare; iar, clientul este
            cel care „furnizează conținutul” procesului de coaching.
          </li>
        </ul>
        <p>
          Coaching-ul este{' '}
          <strong>
            „arta facilitării performanței, învățării și dezvoltării altei
            persoane”
          </strong>{' '}
          – Downey, 2003.
        </p>
      </div>,
    ],
  },
];

export const frecventaOptima = [
  <Stack>
    <Text>
      De obicei, este nevoie de până la 5-7 sesiuni pentru a găsi protocolul
      optim de antrenament pentru fiecare pacient.
    </Text>
    <Text>
      Persoanele a căror creier este <b>supraexcitat</b> au nevoie de relaxare
      și calmare pentru reducerea gândurilor excesive și ruminative, a
      impulsivității și hiperactivității, a plictiselii, nerăbdării și
      agitației, a agresivității. De asemenea neurofeedback-ul scade nivelul de
      energie și astfel sunt reduse anxietatea și frica, stările de încordare,
      sentimentele de neputință și copleșire, durerile și migrenele, precum și
      insomniile.
    </Text>
    <Heading fontSize={'1.25rem'} w={'fit-content'} as={'h2'}>
      Persoanele care se confruntă cu simptome de <b>subexcitare</b> la
      sfârșitul fiecărei sesiuni de antrenament cu neurofeedback ar trebui să se
      simtă mai treze, concentrate și motivate. Un creier subexcitat prezintă
      următoarele simptome:
    </Heading>

    <HStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      gap={'2rem'}
      flexWrap={'wrap'}
    >
      <UnorderedList>
        <ListItem>Deficit de atenție și dificultăţi de concentrare</ListItem>
        <ListItem>Neatenție, uitare frecventă și lipsă de concentrare</ListItem>
        <ListItem>Visarea cu ochii deschişi</ListItem>
        <ListItem>Gândirea ȋn ceaţă</ListItem>
        <ListItem>Lipsă de motivaţie</ListItem>
      </UnorderedList>

      <UnorderedList>
        <ListItem>Depresie</ListItem>
        <ListItem>Letargie</ListItem>
        <ListItem>Stimă de sine scăzută</ListItem>
        <ListItem>Tulburări de somn</ListItem>
      </UnorderedList>
    </HStack>
  </Stack>,
];
