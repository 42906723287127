import { chakra, Box, Heading, Stack } from '@chakra-ui/react';
import React from 'react';

const Hero = () => {
  return (
    <Stack
      w={'full'}
      h={{ base: '219.38px', md: '90vh' }}
      position={'relative'}
      overflow={'hidden'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Heading
        as={'h1'}
        zIndex={3}
        fontSize={{ base: '2rem', md: '4rem' }}
        color={'textLight'}
        textTransform={'uppercase'}
      >
        Cerebrium
      </Heading>
      <Box
        position={'absolute'}
        bgColor={'rgba(0,0,0,0.6)'}
        w={'full'}
        h={{ base: '219.38px', md: '90vh' }}
        zIndex={2}
      ></Box>

      <chakra.video
        position={'absolute'}
        width={'full'}
        autoPlay={true}
        loop={true}
        controls={false}
        muted
        playsInline
        type={'video/mp4'}
        src={'/videos/heroVideo.mp4'}
        zIndex={1}
      />
    </Stack>
  );
};

export default Hero;
