import { HStack, Stack, Text } from '@chakra-ui/react';

export const FbmText = [
  <div className="textsContainer">
    <h2>
      <strong>Ce este fotobiomodularea transcraniană?</strong>
    </h2>
    <p>
      <strong>FOTOBIOMODULAREA TRANSCRANIANĂ (tPBM)</strong> este o terapie
      alternativă care utilizează lumini roșii sau infraroșii pentru a stimula,
      vindeca, regenera și proteja țesutul care fie a fost rănit, fie
      degenerează, fie riscă să moară. Energia luminii este transferată către
      diferitele organe ale celulelor noastre pentru a stimula funcțiile
      metabolice care produc mai multe efecte clinice remarcabile, cum ar fi
      efectele analgezice și antiinflamatorii sau efectele de vindecare. Unul
      dintre sistemele de organe ale corpului uman care este cel mai necesar
      vieții și a cărei funcționare optimă este necesară este{' '}
      <strong>creierul.</strong>
    </p>
    <p>
      Creierul suferă tulburări diferite care pot fi clasificate în trei grupe
      mari:
    </p>
    <ul>
      <li>
        <strong>Evenimente traumatice</strong> (accident vascular cerebral,
        leziune cerebrală traumatică și ischemie globală),
      </li>
      <li>
        <strong>Boli degenerative</strong> (demență, Alzheimer și Parkinson)
      </li>
      <li>
        <strong>Tulburări psihiatrice</strong> (depresie, anxietate, stres
        posttraumatic).
      </li>
    </ul>
    <p>
      Există unele dovezi care arată că toate aceste condiții aparent diverse
      pot fi ameliorate prin aplicarea luminii infraroșii pe cap. PBM poate fi
      utilizată și pentru îmbunătățirea cognitivă la oamenii sănătoși. În
      această aplicație transcraniană PBM (tPBM), lumina în infraroșu apropiat
      (NIR) este adesea aplicată pe frunte datorită penetrării mai bune (fără
      păr, lungime de undă mai mare).
    </p>
    <p>
      Aparatul de fotobiomodulare a creierului este un instrument terapeutic
      bazat pe principiul fotobiomodulării. Casca de terapie cu led are un efect
      terapeutic bun asupra evenimentelor traumatice, bolilor degenerative,
      precum și a tulburărilor psihiatrice.
    </p>
    <p>
      Studiile sugerează că PBM este o strategie eficientă pentru promovarea
      microcirculației și a proliferării și regenerării celulare. Există un
      interes din ce în ce mai mare pentru utilizarea acesteia ca strategie de
      îmbunătățire a performanței la tinerii și vârstnicii sănătoși (antrenament
      de performanță de vârf).
    </p>
    <p>
      Prof. Dr. Michael Hamblin unul dintre cercetătorii de frunte în acest
      domeniu comentează: „Terapia PBM a creierului este o metodă promițătoare
      în care lumina roșie și infraroșie emisă de lasere sau diode emițătoare de
      lumină (LED-uri) este livrată la nivelul scalpului pentru a stimula
      neuronii și funcția creierului. S-a demonstrat că aceasta crește fluxul
      sanguin cerebral și de asemenea crește capacitatea metabolică cerebrală.
      În zilele noastre, aplicațiile terapiei PBM în medicina modernă sunt
      extrem de diverse, așa că a câștigat o credibilitate considerabilă printre
      strategiile terapeutice disponibile.”
    </p>
    <p>
      Printre{' '}
      <strong>
        EFECTELE PRODUSE CU AJUTORUL TERAPIEI PRIN FOTOBIOMODULARE TRANSCRANIANĂ
      </strong>{' '}
      se numără: reglarea metabolică, îmbunătițirea funcțiilor cognitive și a
      capacității metabolice neuronale, activarea și fortificarea sistemului
      imunitar, calmare, stimularea răspunsurilor antiinflamatorii, revitalizare
      și întărire a organismului.
    </p>
    <h3>
      <strong>Cum acționează?</strong>
    </h3>
    <ul className="ulSpacing">
      <li>
        Lumina NIR (infraroșu apropiat) pătrunde prin craniu și ajunge la creier
      </li>
      <li>
        NIR este absorbit de citocrom c oxidază în mitocondrii. Mitocondriile
        sunt răspunzătoare pentru 90% din necesarul de energie al organismului
        nostru, dar ele nu sunt doar centrele de energie. Mai recent, s-a
        constatat că au și roluri non-energetice, adică sesizează stimulii din
        mediu înconjurător, fiind sensibili la factorii de stres, pe baza
        acestora, transmit semnale biochimice celulelor și determină
        reprogramări sau chiar alterări ale ADN-ului mitocondrial. În concluzie,
        mitocondriile influențează direct reglarea metabolică sistemică, funcția
        creierului, activarea imunitară, rata de îmbătrânire și chiar și durata
        de viață.
      </li>
      <li>
        Absorbția de lumină de către canalele ionice contribuie la producerea de
        noi neuroni, la sinaptogeneza (formarea sinapselor între neuroni),
        îmbunătățește capacitatate metabolică a neuronilor, stimulează
        răspunsurile antiinflamatorii, fortifică stistemul imunitar.
      </li>
      <li>
        Eliberarea de oxid nitric. Exact cum serotonina și dopamina din creier
        induc o stare de fericire, oxidul nitric are efect de calmare,
        determinand o reacție în lant, la nivel celular, prin intermediul căreia
        vasele de sânge se relaxează și se dilată. Oxidul nitric controlează
        erecția, revitalizează și întărește organismul (senzația de moleșeală
        înseamnă că ariile corticale responsabile de starea de veghe sunt sărace
        în oxid nitric), menține sănătatea pielii, stimulând circulația
        sângelui.
      </li>
    </ul>
    <br />
    <img
      className="fbmImage"
      src="/images/fbm1.webp"
      alt="Cerebium - Fotobiomodulare"
    />
    <ul className="ulSpacing">
      <h3>
        <strong>Cui se adresează? </strong>
      </h3>
      <li>Îmbunătățirea funcțiilor cognitive la oamenii sănătoși clinic</li>
      <li>Autism</li>
      <li>
        Atrofia creierului, diminuarea funcțiilor cognitive, pierderea memoriei
      </li>
      <li>Insomnie</li>
      <li>
        Evenimente traumatice (accident vascular cerebral, leziune cerebrală
        traumatică, ischemie globală)
      </li>
      <li>Boli degenerative (demență, Alzheimer, Parkinson)</li>
      <li>
        Tulburări ale dispoziției (depresie, anxietate), tulburare de stres,
        tulburare de stres posttraumatic, schizofrenie
      </li>
      <li>Arterioscleroză cerebrală insuficientă</li>
      <li>Tromboză cerebrală, infarct cerebral, infarct lacunar</li>
      <li>Cefalee nervoasă</li>
      <li>Neurastenie</li>
    </ul>
  </div>,
];

export const TimpText = [
  <div className="textsContainer">
    <h2>
      <strong>Cât timp este recomandată terapia BPM?</strong>
    </h2>
    <p>
      Pentru bolile cronice pe termen lung, cum ar fi bolile degenerative se
      recomandată timp de 3-6 luni.
    </p>
    <p>
      Pentru simptome de scurtă durată, precum comoție, migrenă, depresie etc.,
      se recomandată timp de 1-3 luni.
    </p>
    <p>
      În funcție de severitatea simptomelor pacientului, se poate utiliza de 4-6
      ori pe săptămână, de 1-2 ori pe zi. La începutul utilizarii, se recomandă
      să se acorde pacientului un proces de adaptare.
    </p>
    <img
      className="fbmImage2"
      src="/images/fbm3.webp"
      alt="Cerebium - Fotobiomodulare"
    />
    <h3>
      <strong>Timpul și frecvența:</strong>
    </h3>
    <div className="rowUl">
      <ul className="ulSpacing">
        <li>
          Arterioscleroza cerebrală, Insuficienta alimentare cu sânge a
          creierului - 30 minute - 50 Hz
        </li>
        <li>Atrofie cerebrală - 30 minute - 40-50 Hz</li>
        <li>Boala Alzheimer - 30 minute - 50 Hz</li>
        <li>Copiii cu autism - 30 minute - 10-30 Hz</li>
        <li>Tromboză cerebrală, Infarct cerebral - 30 minute - 50 Hz</li>
        <li>Infarct lacunar - 30 minute - 50 Hz</li>
        <li>
          Recuperarea leziunii cerebrale traumatice după operație - 30 minute -
          30 Hz
        </li>

        <li>
          Perioada de recuperare după otrăvirea cerebrală - 30 minute - 30-50 Hz
        </li>
        <li>Copiii cu paralizie cerebrală 20-30 minute - 5-15 Hz</li>
      </ul>
      <ul className="ulSpacing">
        <li>Boala Parkinson - 30 minute - 30-50 Hz</li>
        <li>Depresie 20-30 minute - 50 Hz</li>
        <li>Tulburări psihice 20-30 minute - 30-50 Hz</li>
        <li>Insomnie 20-30 minute - 5-15 Hz</li>
        <li>Cefalee nervoasă 20-30 minute - 20-40 Hz</li>
        <li>Hipertensiune arterială 20-30 minute - 5-15 Hz</li>
        <li>Oboseală cerebrală 20-30 minute - 30 Hz</li>
        <li>Pierdere memorie 20-30 minute - 30 Hz</li>
        <li>Neurastenie 20-30 minute - 5-20 Hz</li>
        <li>Schizofrenie 20-30 minute - 5-20 Hz</li>
      </ul>
    </div>
  </div>,
];
export const TsaText = [
  <div className="textsContainer">
    <HStack
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      alignItems={'center'}
      w={'full'}
      gap={'2rem'}
    >
      <img
        className="fbmImage"
        src="/images/fbm2.webp"
        alt="Cerebium - Fotobiomodulare"
      />
      <Stack h={'full'} minH={'670px'} justifyContent={'space-evenly'}>
        <Text maxW={'800px'} w={'full'}>
          Autismul este o tulburare de neurodezvoltare care este diagnosticată
          în jurul vârstei de 3 ani, afectând de patru până la cinci ori mai
          mult băieții decât fetele. În prezent, există tot mai multe dovezi că
          autismul este asociat cu o disfuncție mitocondrială, neuroplasticitate
          și dezvoltarea sinaptică.
        </Text>
        <Text maxW={'800px'} w={'full'}>
          Tratamentul în tulburarea din spectru autist urmărește reducerea
          problemelor comportamentale, creșterea calității vieții și dobândirea
          unei independențe funcționale. În ceea ce privește medicamentele,
          deseori sunt prescrise medicamente psihoactive sau anticonvulsivante,
          cum ar fi antidepresive, stimulente și antipsihotice.
        </Text>
        <Text maxW={'800px'} w={'full'}>
          Fotobiomodularea a fost utilizată pe scară largă pentru reducerea
          durerii, inflamației și stimularea vindecării rănilor sau a altor
          tipuri de leziuni, boala Alzheimer sau demența. Deoarece aceasta nu
          implică medicamente sau modalități invazive, este lipsită de efecte
          secundare.
        </Text>
      </Stack>
    </HStack>
    <p>
      Până în prezent, terapia prin fotobiomodulare și-a dovedit eficiența în:
      îmbunătățirea funcției mitocondriale, creșterea citocromului C oxidază,
      oxigenarea cerebrală, producția de ATP și îmbunătățirea sinaptogenezei.
    </p>
    <ul className="ulSpacing">
      <h3>
        În ceea ce privește utilitatea în tulburarea din spectru autist, un
        studiu realizat anul trecut de Hamblin, a indicat îmbunătățiri în
        următoarele arii:
      </h3>
      <li>Conștientizare socială</li>
      <li>Comunicare</li>
      <li>Motivație socială</li>
      <li>
        Reducerea comportamentelor restricționate/ repetitive; conducând la o
        îmbunătățire în privința scorului obținut la Evaluarea Globală a
        Funcționării.
      </li>
    </ul>
    <p>
      Astfel, fotobiomodularea și neurofeedback-ul se alătură celorlalte terapii
      în reducerea simptomatologiei asociate tulburării din spectru autist. În
      plus, având în vedere influența acesteia asupra sinaptogenezei și
      neuroplasticității, fotobiomodularea poate fi utilizată împreună cu
      celelalte metode educaționale și psihoterapeutice, amplificând
      rezultatele.
    </p>
  </div>,
  <div className="textsContainer">
    <h2>
      <strong>Terapia cu lumină roșie în DEPRESIE</strong>
    </h2>
    <p>
      Dr. Marc Schoen de la UCLA Medical School are peste 25 de ani de
      experiență în tratarea tulburărilor psihologice. Acesta menționează faptul
      că utilizarea intervențiilor farmaceutice, împreună cu psihoterapia /
      hipnoza pentru pacienții cu episoade depresive majore acute sau recurente
      necesită perioade lungi de timp pentru ca rezultatele să se materializeze,
      în timp ce adăugarea terapiei cu lumină roșie și NIR pare să accelereze
      semnificativ procesul de vindecare, dovedindu-se la fel de eficient ca
      citalopramul.
    </p>
    <p>Această terapie este sigură și bine tolerată, fără efecte secundare.</p>
    <p>
      Același succes a fost identificat și în cazul pacienților cu simptome
      obsesiv-compulsive precum smulgerea părului.
    </p>
    <p>
      Examinând chimia creierului, studiile concluzionează faptul că
      tratamentele prin terapie cu lumină cresc nivelurile de antioxidanți și
      serotonină, în timp ce scad nivelurile de cortizol, stres și
      neuroinflamația.
    </p>
  </div>,
];
