import { Box, HStack, Heading, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Membru = (props) => {
  return (
    <HStack
      gap={{ base: '1rem', md: '4rem' }}
      flexDir={{ base: 'row', md: props.flex ? 'row-reverse' : 'row' }}
      flexWrap={'wrap'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Stack alignItems={'center'} justifyContent={'flex-start'}>
        <Image
          width={{ base: '250px' }}
          height={'250px'}
          fit={'cover'}
          loading="lazy"
          borderRadius={'50%'}
          alt={props.name}
          src={props.src}
        />
        <Link to="/contact">
          <Box
            w={'fit-content'}
            bg={'green'}
            color={'textLight'}
            fontWeight={'bold'}
            borderRadius={'1rem'}
            px={'1.25rem'}
            py={'.5rem'}
          >
            Contactează-mă
          </Box>
        </Link>
      </Stack>
      <Stack alignItems={{ base: 'center', md: 'flex-start' }}>
        <Heading w={'fit-content'} as={'h2'}>
          {props.name}
        </Heading>
        <Heading
          fontSize={'1.25rem'}
          color={'Green'}
          w={'fit-content'}
          as={'h3'}
        >
          {props.titluri}
        </Heading>
      </Stack>
    </HStack>
  );
};

export default Membru;
