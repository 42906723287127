import {
  chakra,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Divider,
  Icon,
} from '@chakra-ui/react';
import React from 'react';
import { CiInstagram, CiFacebook } from 'react-icons/ci';

const ContactFormCard = (props) => {
  const sendMailHandler = () => {
    console.log('TADAAAAAA');
  };
  return (
    <HStack
      maxW={'100rem'}
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      color={'textLight'}
      gap={{ md: '4rem' }}
      bg={'rgba(255,255,255,0.2)'}
      boxShadow={'0 4px 30px rgba(0,0,0,0.1)'}
      backdropFilter={'blur(6px)'}
      p={'1.5rem'}
      borderRadius={'1rem'}
      border={'1px solid #fff'}
      flexWrap={'wrap'}
    >
      <Stack>
        <Stack>
          <Heading
            textDecoration={'underline'}
            w={'fit-content'}
            as={'h2'}
            fontSize={'1.5rem'}
          >
            {props.city}
          </Heading>
          <Text w={'fit-content'}>Cerebrium</Text>
          <Text
            color={'secondGreen'}
            fontWeight={'bold'}
            fontSize={'1.5rem'}
            w={'fit-content'}
          >
            {props.name}
          </Text>
        </Stack>
        <Stack alignItems={'flex-start'}>
          <Heading
            textDecoration={'underline'}
            w={'fit-content'}
            as={'h2'}
            fontSize={'1.5rem'}
          >
            Adresa:
          </Heading>
          <Text>{props.adresa}</Text>

          <Heading
            textDecoration={'underline'}
            w={'fit-content'}
            as={'h2'}
            fontSize={'1.5rem'}
          >
            Telefon:
          </Heading>
          <Link href={`callto:${props.telefon}`}>{props.telefon}</Link>
        </Stack>
        <Divider />
        <HStack>
          <Link
            target="_blank"
            aria-label="Facebook"
            rel="noreferrer"
            href={props.fb}
            fontSize={'2.5rem'}
          >
            <Icon as={CiFacebook} />
          </Link>
          <Link
            target="_blank"
            aria-label="Instagram"
            rel="noreferrer"
            href={props.insta}
            fontSize={'2.5rem'}
          >
            <Icon as={CiInstagram} />
          </Link>
        </HStack>
      </Stack>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <chakra.form color={'textLight'} w={'280px'} onSubmit={sendMailHandler}>
          <Stack gap={'1rem'}>
            <FormControl isRequired>
              <FormLabel>Nume:</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email:</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Telefon:</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Mesaj:</FormLabel>
              <Textarea />
            </FormControl>
            <FormControl>
              <Button type="submit" bg={'secondGreen'} color={'textLight'}>
                Trimite Mesaj
              </Button>
            </FormControl>
          </Stack>
        </chakra.form>
      </Stack>
    </HStack>
  );
};

export default ContactFormCard;
