import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = (props) => {
  return (
    <Stack
      w={'300px'}
      h={'400px'}
      bg={'rgba(255,255,255,0.4)'}
      border={'1px solid #fff'}
      backdropFilter={'blur(6px)'}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={'2rem'}
      borderRadius={'1rem'}
    >
      <Heading
        as={'h2'}
        w={'fit-content'}
        fontSize={'1.5rem'}
        textAlign={'center'}
      >
        {props.title}
      </Heading>
      <Box textAlign={'center'}>
        <Text w={'fit-content'}>
          Detalii referitoare la prețuri le puteți obține la{' '}
          <Link
            style={{ color: '#1c1874', textDecoration: 'underline' }}
            to="/contact"
          >
            telefon
          </Link>{' '}
        </Text>
      </Box>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        w={'200px'}
        h={'70px'}
        bg={props.online ? '#bbfdb9' : '#fdb9b9'}
        borderTopRightRadius={'1.5rem'}
        borderBottomLeftRadius={'1.5rem'}
      >
        <Text fontWeight={'bold'} w={'fit-content'}>
          Online: {props.online ? 'DISPONIBIL' : 'INDISPONIBIL'}
        </Text>
      </Stack>
      <Link to={props.link}>
        <Stack
          bg={'mainBlue'}
          color={'textLight'}
          justifyContent={'center'}
          alignItems={'center'}
          p={'.5rem 1rem'}
          borderRadius={'1.5rem 0 1.5rem 0'}
        >
          <Text w={'fit-content'}>Mai multe detalii</Text>
        </Stack>
      </Link>
    </Stack>
  );
};

export default ServiceCard;
