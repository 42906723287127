export const homeCards = [
  {
    src: '/images/Bg2.webp',
    alt: 'Neurofeedback - Afectiuni',
    title: 'Afecțiuni',
    description:
      'Neurofeedback-ul este recomandat pentru o gamă largă de afecțiuni ce pot fi ținute sub control cu ajutorul neurofeedback-ului cu frecvențe foarte joase. Puteți vedea aici lista completă și beneficiile aduse în urma sedințelor de neurofeedback.',
    link: '/neurofeedback/afectiuni',
    button: 'Mai multe detalii',
  },
  {
    src: '/images/NeuroBG.webp',
    alt: 'Neurofeedback',
    title: 'Neurofeedback ILF',
    description:
      'Neurofeedback-ul este o metodă de antrenare directă a funcțiilor cerebrale cu ajutorul căreia creierul învață să funcționeze mai eficient. Autoreglarea permite sistemului nervos central să funcționeze mai bine. Numeroase afecțiuni au la bază lipsa unei reglări naturale a creierului și a proceselor implicate.',
    link: '/neurofeedback',
    button: 'Mai multe detalii',
  },
  {
    src: '/images/frecventa.webp',
    alt: 'Neurofeedback - Frecventa Optima',
    title: 'Frecvența Optimă',
    description:
      'Ce înseamnă frecvența optimă și care este aplicabilitatea acesteia? Vezi despre beneficiile și importanța aflării frecvenței optime pentru creierul tău.',
    link: '/neurofeedback/frecventa-optima',
    button: 'Mai multe detalii',
  },
];
