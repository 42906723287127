import { Box, HStack, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const InfoSection = (props) => {
  return (
    <Stack
      w={'full'}
      minH={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      bgImage={'/images/backgroundWeb.webp'}
      bgRepeat={'no-repeat'}
      bgSize={'cover'}
      bgPos={'center'}
    >
      <HStack
        gap={'1rem'}
        flexWrap={'wrap'}
        flexDir={{ base: 'row', md: props.flex ? 'row-reverse' : 'row' }}
        justifyContent={'center'}
      >
        <Box>
          <Image
            loading="lazy"
            maxW={{ base: '350px', md: '700px' }}
            src={props.src}
            alt="Cerebrium - Neurofeedback"
          />
        </Box>
        <Stack color={'textLight'}>
          <Heading color={'mainBlue'} fontSize={'1.25rem'} as={'h3'}>
            {props.subTitle}
          </Heading>
          <Heading fontSize={{ base: '2rem', md: '3rem' }} as={'h2'}>
            {props.title}
          </Heading>
          <Text fontWeight={'bold'} w={{ base: '330px', md: '400px' }}>
            {props.text}
          </Text>
          <Link to={`${props.link}`}>
            <Box
              w={'fit-content'}
              bg={'mainBlue'}
              px={'1.5rem'}
              py={'.75rem'}
              borderRadius={'1rem'}
              fontWeight={'bold'}
            >
              <Text w={'fit-content'}>{props.buttonText}</Text>
            </Box>
          </Link>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default InfoSection;
