import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Header } from '../../components';
import { afectiuniData } from '../../static/ModalData';

const Afectiuni = () => {
  return (
    <Stack w={'full'} justifyContent={'center'} alignItems={'center'} gap={0}>
      <Header text={'Ce afectiuni se pot trata?'} />
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        py={'2rem'}
        px={'2.5rem'}
        bg={
          'radial-gradient(rgb(144, 199, 71), rgb(128, 184, 68), rgb(113, 168, 66), rgb(99, 153, 62), rgb(85, 138, 58), rgb(73, 123, 54), rgb(62, 108, 50), rgb(51, 94, 45), rgb(42, 80, 40), rgb(33, 66, 34))'
        }
      >
        <Accordion w={'full'} maxW={'100rem'} color={'textLight'} allowMultiple>
          {afectiuniData.map((item) => (
            <AccordionItem key={item.id}>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    fontWeight={'bold'}
                    fontSize={'1.25rem'}
                    flex="1"
                    textAlign="left"
                  >
                    {item.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{item.text}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default Afectiuni;
