import { Box, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Cards = (props) => {
  return (
    <Stack
      overflow={'hidden'}
      justifyContent={'space-between'}
      alignItems={'center'}
      w={'330px'}
      borderRadius={'1.25rem'}
      minH={'500px'}
      color={'textLight'}
      gap={'2rem'}
      bg={'rgba(255,255,255,0.2)'}
      boxShadow={'0 4px 30px rgba(0,0,0,0.1)'}
      backdropFilter={'blur(6px)'}
      paddingBottom={'1rem'}
    >
      <Image
        loading="lazy"
        w={'full'}
        h={'200px'}
        src={props.src}
        alt={props.alt}
      />
      <Stack maxW={'250px'} alignItems={'center'}>
        <Heading as={'h3'} fontSize={'1.5rem'}>
          {props.title}
        </Heading>
        <Text>{props.description}</Text>
      </Stack>
      <Link to={`${props.link}`}>
        <Box
          w={'fit-content'}
          bg={'none'}
          border={'1px solid #fff'}
          borderRadius={'1rem'}
          px={'1.25rem'}
          py={'.5rem'}
        >
          {props.button}
        </Box>
      </Link>
    </Stack>
  );
};

export default Cards;
