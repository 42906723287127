import {
  HStack,
  Heading,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import { Header } from '../components';
import Membru from '../components/Membru';
import { echipaData } from '../static/staticData';

const About = () => {
  const andreeaData = echipaData[2];
  const ionelaData = echipaData[0];
  const iulianaData = echipaData[1];
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'flex-start'}
      w={'full'}
      className="contactContainer"
      gap={0}
    >
      <Header text={'Echipa Cerebrium'} />
      <Stack alignItems={'center'} justifyContent={'center'} py={'1rem'}>
        <Tabs align="center" variant={'unstyled'}>
          <TabList>
            <Tab
              fontWeight={'bold'}
              fontSize={'1.25rem'}
              _selected={{
                borderWidth: '3px',
                borderColor: 'secondBlue',
                borderBottom: 0,
                borderRadius: '.4rem',
              }}
            >
              Echipa
            </Tab>
            <Tab
              fontSize={'1.25rem'}
              fontWeight={'bold'}
              _selected={{
                borderWidth: '3px',
                borderColor: 'secondBlue',
                borderBottom: 0,
                borderRadius: '.4rem',
              }}
            >
              Locație
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Tabs align="center" variant={'unstyled'}>
                <TabList>
                  <Tab fontSize={'1.25rem'} fontWeight={'bold'}>
                    Constanța
                  </Tab>
                  <Tab fontSize={'1.25rem'} fontWeight={'bold'}>
                    Tulcea
                  </Tab>
                </TabList>
                <TabIndicator
                  height={'3px'}
                  bg={'secondOrange'}
                  borderRadius={'1px'}
                />
                <TabPanels>
                  <TabPanel>
                    <Membru
                      name={andreeaData.nume}
                      src={andreeaData.poza}
                      titluri={andreeaData.titluri}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Stack gap={'4rem'}>
                      <Membru
                        name={ionelaData.nume}
                        src={ionelaData.poza}
                        titluri={ionelaData.titluri}
                      />
                      <Membru
                        name={iulianaData.nume}
                        src={iulianaData.poza}
                        titluri={iulianaData.titluri}
                        flex={true}
                      />
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
            <TabPanel>
              <HStack gap={'4rem'} flexWrap={'wrap'} className="locationMaps">
                <Stack alignItems={'center'} justifyContent={'center'}>
                  <Heading as={'h3'} fontSize={'1.25rem'} color={'mainOrange'}>
                    Constanța
                  </Heading>
                  <Heading as={'h2'} fontSize={{ md: '1.5rem' }}>
                    Bvd Al. Lăpușneanu, Nr. 94, Bl. LE42, Sc. C, Ap. 42, Parter
                  </Heading>
                  <iframe
                    title="constanta"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d715.1936692899599!2d28.63332682926472!3d44.19110899869417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xedfdebc4e7545409!2zNDTCsDExJzI4LjAiTiAyOMKwMzgnMDIuMCJF!5e0!3m2!1sen!2sro!4v1652635334532!5m2!1sen!2sro"
                    width="700"
                    height="450"
                    loading="lazy"
                  ></iframe>
                </Stack>
                <Stack alignItems={'center'} justifyContent={'center'}>
                  <Heading as={'h3'} fontSize={'1.25rem'} color={'mainOrange'}>
                    Tulcea
                  </Heading>
                  <Heading as={'h2'} fontSize={{ md: '1.5rem' }}>
                    Str. Isaccei, Nr. 31 Bl. 18, Sc. A, Ap. 6 - Parter
                  </Heading>
                  <iframe
                    title="tulcea"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d703.0974012619636!2d28.79272682936097!3d45.179209000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b7595b1a424da5%3A0xe280715bec144458!2sStrada%20Isaccei%2031%2C%20Tulcea!5e0!3m2!1sen!2sro!4v1652635586738!5m2!1sen!2sro"
                    width="700"
                    height="450"
                    loading="lazy"
                  ></iframe>
                </Stack>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default About;
