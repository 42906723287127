import { Stack } from '@chakra-ui/react';
import React from 'react';
import { Header } from '../../components';
import { FbmText } from '../../static/FotobiomodulareData';

const Fbm = () => {
  return (
    <Stack w={'full'} justifyContent={'center'} alignItems={'center'} gap={0}>
      <Header text={'Ce este Fotobiomodularea Transcraniana'} />
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        py={'2rem'}
        px={'2.5rem'}
        bg={
          'radial-gradient(rgb(144, 199, 71), rgb(128, 184, 68), rgb(113, 168, 66), rgb(99, 153, 62), rgb(85, 138, 58), rgb(73, 123, 54), rgb(62, 108, 50), rgb(51, 94, 45), rgb(42, 80, 40), rgb(33, 66, 34))'
        }
      >
        <Stack
          w={'full'}
          maxW={'100rem'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Stack whiteSpace={'pre-line'} lineHeight={2} color={'textLight'}>
            {FbmText}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Fbm;
