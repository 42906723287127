export const brainData = [
  <div className="textsContainer">
    <p className="formated-text">
      <strong>Brain Mapping-ul</strong> sau qEEG (Electroencefalograma
      Cantitativă) este un <strong>instrument de diagnostic</strong> de ultimă
      generație, o metodă științifică prin intermediul căreia{' '}
      <u>sunt măsurate dezechilibrele la nivel neuronal</u>. Este o procedură
      sigură și non-invazivă
    </p>
    <p className="formated-text">
      <strong>Brain Mapping-ul</strong> este procedeul de scanare a creierului
      la nivel cognitiv, executiv și funcțional care oferă acces direct la
      funcționalitatea ariilor importante ale creierului. Este practic o hartă a
      creierului care arată într-un mod concret și obiectiv nivelul de
      funcționare al fiecărei arii neuronale și ajută la identificarea
      dezechilibrelor diferitelor regiuni cerebrale.
    </p>
    <p className="formated-text">
      Cercetările moderne au stabilit că numeroase tulburari la nivelul
      psihicului (stres, traume, depresie, anxietate, ADD/ADHD, problemele ale
      somnului, migrene, probleme de concentrare, boli psihice, tulburări
      somatice și de comportament, tulburări neuropsihice) sunt evidențiate
      printr-o activitate electrică dezechilibrată a anumitor arii cerebrale.
      Așadar, prin identificarea regiunilor neuronale care funcționează
      deficitar putem avea o imagine clară, obiectivă și specifică a problemelor
      afective, emoționale sau mintale.
    </p>
    <p className="formated-text">
      <strong>Brain Mapping-ul</strong> reprezintă{' '}
      <strong>unul dintre cele mai importante instrumente de diagnostic</strong>{' '}
      disponibile în domeniul neurofeedback-ului.
    </p>
    <h2>
      <strong>De ce să faci un Brain Mapping?</strong>
    </h2>
    <p className="formated-text">
      Pentru că ai posibilitatea de a avea o imagine clară asupra a ceea ce se
      întâmplă în creierul tău în 3 situații în timp real (ochi închiși, ochi
      deschiși, sarcină) și oferă posibilitatea stabilirii planului de tratament
      unic pentru fiecare persoană și fiecare situație în parte.
    </p>
    <p className="formated-text">
      <strong>Rezultatul Brain Mapping-ului</strong> va descoperi tiparul
      oricărei unde cerebrale care cauzează probleme. Odată ce scanarea este
      completă, vor fi evaluate rezultatele prin accesarea detaliilor despre
      fiecare undă în parte, inclusiv dezechilibrul lor și se va stabili un
      protocol de tratament.
    </p>

    <ul style={{ width: '100%' }}>
      <li>
        Prin brainmapping este posibilă evaluarea diferitelor patternuri ale
        creierului și care dintre ele au nevoie de antrenament.
      </li>
      <li>
        Oferă o imagine de ansamblu asupra stării creierului din punct de vedere
        al activității lui electrice.
      </li>
      <li>
        Poate identifica traumele, atât cele psihice cât și cele fizice de la
        nivel cerebral.
      </li>
    </ul>
    <img
      className="bm bi2"
      src="/images/brain1.jpg"
      alt="Cerebrium Brain Mapping"
    />
    <h2>
      <strong>Cum ajută Brain Mapping-ul?</strong>
    </h2>
    <p className="formated-text">
      Rolul hărților a fost întotdeauna acela de a ne îndruma și de a ne ajuta
      cu informații. Lucrurile stau similar și în cazul Brain Mapping-ului.{' '}
    </p>
    <p className="formated-text">
      Acest procedeu reprezintă cel mai util instrument pentru specialiștii care
      activează în domeniul sănătății mintale. Astfel, pentru un terapeut brain
      mapping-ul este esențial în înțelegerea proceselor și a dificultăților cu
      care clientul se confruntă și îl ajută la o diagnosticare corectă pe baza
      căreia poate dezvolta un plan de tratament personalizat, în concordanță cu
      nevoile clientului. 
    </p>
    <p className="formated-text">
      În timp ce Brain Mapping-ul oferă informații despre relația dintre
      anomaliile din funcția electrică a creierului și numeroasele tulburări de
      comportament, emoție, gândire, învățare și dezvoltare,{' '}
      <strong>
        modelele neuronale pot fi îmbunătățite cu ajutorul mai multor forme de
        terapie
      </strong>{' '}
      sau metode moderne precum: <strong>neurofeedback-ul, psihoterapia</strong>
      , hipnoterapia, etc.
    </p>
    <p className="formated-text">
      Nurofeedback-ul este una dintre cele mai moderne metode de antrenare a
      creierului și de corectare a modelelor de învățare. Este o tehnică
      non-invazivă, renumită pentru rata de eficiență în dezvoltarea de noi
      modele mai sănătoase pentru creier.
    </p>
    <p className="formated-text">
      Brain Mappingul arată rezultatele obținute în urma evaluării creierului
      care sunt folosite pentru a determina și evalua starea actuală a
      creierului. Va descoperi tiparul oricărei unde cerebrale care cauzează
      probleme. În timpul scanării EEG vor fi lipiți temporar o serie de
      electrozi pe scalp, iar senzorii vor monitoriza și măsura activitatea
      electrică a creierului. Această scanare nu cauzează niciun fel de durere
      și durează aproximativ 40 – 50 de minute. O scanare și evaluare a
      creierului este primul pas pentu terapia neurofeedback și este folosită
      pentru evaluarea stării actuale prin măsurarea celor 5 tipuri de unde
      cerebrale (delta, theta, alpha, betha, gama).{' '}
    </p>
    <p className="formated-text">
      QEEG înregistrează datele de la 20 de locuri EEG standard de pe cap. Acest
      lucru se face în condițiile de lucru cu ochii închiși, ochii deschiși și
      în sarcină. Rezultatul este o imagine a modului în care activarea
      creierului se schimbă de la repaus la conștiență, până la stări de
      procesare activă. Q-ul elimină „artefactele” (semnale electrice din EEG
      care nu provin de la creier) și prezintă datele în termeni de principalele
      măsuri ale activității creierului: frecvență, amplitudine, stabilitate și
      conectivitate.{' '}
    </p>
    <p className="formated-text">
      <strong>
        {' '}
        Principalele beneficii ale realizării unui Brain Mapping:
      </strong>
    </p>
    <ul>
      <li>
        Evidențierea diferitelor arii specifice ale creierului care nu
        funcționează optim, gene­rând modificări în starea emoțională, în
        capacitatea de concentrare, comportament, focus, somn, învățare, etc;
      </li>
      <li>Identificarea problemelor specifice fiecărei arii corticale;</li>
      <li>
        Obținerea de informații ce pot ajuta la crearea unei scheme de tratament
        optim;
      </li>
      <li>
        Ajută la înțelegerea cauzei pentru care eventualele intervenții
        anterioare nu au ajutat;
      </li>
      <li>
        Indică ce protocoale specifice trebuie folosite în schema de antrenament
        neurofeedback.
      </li>
    </ul>

    <p className="formated-text">
      <strong>Evaluarea propriu- zisă constă în 3 etape:</strong>
    </p>
    <ul>
      <li>completarea unui chestionar;</li>
      <li>maparea creierului – etapă ce durează aproximativ 40-50 minute;</li>
      <li>realizarea raportului și discutarea acestuia cu clientul.</li>
    </ul>
    <img
      className="bm bi3"
      src="/images/brain2.jpg"
      alt="Cerebrium Brain Mapping"
    />
  </div>,
];
