import { HStack, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { serviciiData } from '../static/staticData';

const Service = () => {
  const params = useParams();
  const { title, text } = serviciiData.find((x) => x.slug === params.slug);
  return (
    <Stack
      w={'full'}
      py={'2rem'}
      px={'2.5rem'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Stack
        w={'full'}
        maxW={'100rem'}
        alignItems={'flex-start'}
        justifyContent={'center'}
      >
        <Link to={'/servicii'}>
          {' '}
          <HStack textTransform={'uppercase'} fontSize={'1.5rem'}>
            <BsArrowLeftCircle />
            <Text>înapoi la servicii</Text>
          </HStack>
        </Link>
        <Stack alignItems={'center'} justifyContent={'center'} gap={'2rem'}>
          <Heading as={'h1'} w={'fit-content'}>
            {title}
          </Heading>
          {text.map((item) => item)}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Service;
