import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Stack
      w={'full'}
      minH={'200px'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={`url('/images/Bg2.webp')`}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      bgPos={'center'}
      position={'relative'}
      color={'textLight'}
      gap={['2rem', null, '3rem']}
      py={'1rem'}
    >
      <Box
        position={'absolute'}
        bgColor={'rgba(0,0,0,0.6)'}
        w={'full'}
        h={'full'}
        zIndex={1}
      ></Box>
      <HStack
        w={'full'}
        maxW={'90rem'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={'2rem'}
        zIndex={2}
        gap={{ base: '2rem', md: 0 }}
      >
        <Stack
          gap={'1rem'}
          w={{ base: 'full', md: '250px' }}
          alignItems={'center'}
          fontSize={'1.1rem'}
        >
          <Heading fontSize={'1.25rem'} as={'h3'} textDecor={'underline'}>
            Meniu
          </Heading>
          <Link to={'/'}>Acasă</Link>
          <Link to={'/despre'}>Despre Noi</Link>
          <Link to={'/servicii'}>Servicii</Link>
          <Link to={'/contact'}>Contact</Link>
        </Stack>
        <Stack
          gap={'1rem'}
          w={{ base: 'full', md: '250px' }}
          alignItems={'center'}
          fontSize={'1.1rem'}
        >
          <Heading fontSize={'1.25rem'} as={'h3'} textDecor={'underline'}>
            Informații Utile
          </Heading>
          <Link to={'/neurofeedback'}>Neurofeedback</Link>
          <Link to={'/brain-mapping'}>Brain Mapping</Link>
          <Link to={'/heg'}>HEG - NIR</Link>
          <Link to={'/fotobiomodulare'}>Fotobiomodulare</Link>
        </Stack>
        <Stack
          gap={'1rem'}
          w={{ base: 'full', md: '250px' }}
          alignItems={'center'}
          fontSize={'1.1rem'}
        >
          <Heading fontSize={'1.25rem'} as={'h3'} textDecor={'underline'}>
            Politici
          </Heading>
          <Link to={'/termeni-conditii'}>Termeni și condiții</Link>
          <Link to={'/gdpr'}>Politica GDPR</Link>
        </Stack>
      </HStack>
      <Divider
        zIndex={2}
        maxW={'100rem'}
        w={'full'}
        bgColor={'#fff'}
        h={'2px'}
      />
      <HStack
        bgColor={'titleDark'}
        w={'full'}
        maxW={'100rem'}
        alignItems={'center'}
        justifyContent={{ base: 'center', md: 'space-between' }}
        px={{ base: '1rem', lg: 0 }}
        minH={'70px'}
        zIndex={2}
        flexWrap={'wrap'}
        gap={'2rem'}
      >
        <Image width={'200px'} src={'/images/cerebrium-lw.png'} />

        <Text>
          Cerebrium
          <strong>&#9400; {new Date().getFullYear()}</strong>. All rights
          reserved.
        </Text>
        <Text> Created by Digidev Innotech SRL.</Text>
      </HStack>
    </Stack>
  );
};

export default Footer;
