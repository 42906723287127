import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import { Header } from '../components';
import ContactFormCard from '../components/ContactFormCard';

const Contact = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      w={'full'}
      className="contactContainer"
      gap={0}
    >
      <Header
        text={'Pentru programarea unei ședințe, ne poți contacta mai jos!'}
      />
      <Stack
        w={'full'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'center'}
        py={'2rem'}
        position={'relative'}
        bgImage={'/images/Bg1.webp'}
        bgRepeat={'no-repeat'}
        bgSize={'cover'}
        bgPos={'center'}
        px={{ base: '1rem', md: 0 }}
        overflow={'hidden'}
      >
        <Box
          width={'full'}
          h={'full'}
          position={'absolute'}
          zIndex={1}
          inset={'0 0 0 0'}
          bgColor={'rgba(0,0,0,0.5)'}
        ></Box>
        <Heading
          zIndex={2}
          as={'h2'}
          fontSize={{ base: '1rem', md: '1.25rem' }}
          color={'textLight'}
          textAlign={'center'}
        >
          * anumite ședințe se pot desfășura atât online cât și la cabinet
        </Heading>
        <Tabs
          w={'full'}
          maxW={'900px'}
          zIndex={2}
          align="center"
          variant={'unstyled'}
        >
          <TabList color={'textLight'}>
            <Tab
              fontWeight={'bold'}
              fontSize={'1.25rem'}
              _selected={{
                borderWidth: '3px',
                borderColor: 'secondBlue',
                borderBottom: 0,
                borderRadius: '.4rem',
              }}
            >
              Constanta
            </Tab>
            <Tab
              fontWeight={'bold'}
              fontSize={'1.25rem'}
              _selected={{
                borderWidth: '3px',
                borderColor: 'secondBlue',
                borderBottom: 0,
                borderRadius: '.4rem',
              }}
            >
              Tulcea
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ContactFormCard
                city="Constanța"
                name="Carp Andreea Simona"
                adresa="Bvd Al. Lăpușneanu, Nr. 94,
Bl. LE42, Sc. C, Ap. 42, Parter"
                telefon="+40742323753"
                fb="https://www.facebook.com/Neurofeedback-Constanta-Cerebrium-102789452359176"
                insta="https://www.instagram.com/cerebrium_neurofeedback/"
              />
            </TabPanel>
            <TabPanel>
              <Stack gap={'2rem'}>
                <ContactFormCard
                  city="Tulcea"
                  name="Carp Ionela"
                  adresa="Str. Isaccei, Nr. 31
Bl. 18, Sc. A, Ap. 6 - Parter"
                  telefon="+40752028775"
                  fb="https://www.facebook.com/profile.php?id=100082986146073"
                  insta="https://www.instagram.com/neurofeedbacktulceacerebrium/"
                />
                <ContactFormCard
                  city="Tulcea"
                  name="Trofim Iuliana"
                  adresa="Str. Isaccei, Nr. 31
Bl. 18, Sc. A, Ap. 6 - Parter"
                  telefon="+40752028775"
                  fb="https://www.facebook.com/profile.php?id=100082986146073"
                  insta="https://www.instagram.com/neurofeedbacktulceacerebrium/"
                />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default Contact;
