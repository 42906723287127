import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ toggle }) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <Box
      w={'full'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      h={'150px'}
      color={'textLight'}
      bgColor={'mainBlue'}
      as={'section'}
    >
      <Box
        as={'nav'}
        w={'full'}
        px={['2rem', null, 0]}
        maxW={'90rem'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={10}
      >
        <Link to={'/'}>
          <Image
            hideFrom={'md'}
            w={'200px'}
            objectFit={'contain'}
            src={'/images/cerebrium-lw.png'}
            alt={'Auto Post'}
          />
        </Link>
        {isDesktop ? (
          <Flex
            w={'full'}
            alignItems={'center'}
            justifyContent={'center'}
            fontSize={'1rem'}
            flex={1}
          >
            <HStack gap={'1rem'} textTransform={'uppercase'}>
              <Link to={`/`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  acasă
                </Text>
              </Link>
              <Link to={`/despre`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  despre noi
                </Text>
              </Link>
              <Link to={`/brain-mapping`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  brain mapping
                </Text>
              </Link>

              <Menu>
                <MenuButton as={'Button'} rightIcon={<ChevronDownIcon />}>
                  NEUROFEEDBACK <ChevronDownIcon />
                </MenuButton>
                <MenuList bg={'mainBlue'} border={'none'}>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/neurofeedback/ce-este-neurofeedback'}>
                      Ce este Neurofeedback-ul?
                    </Link>
                  </MenuItem>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/neurofeedback/afectiuni'}>
                      Ce afecțiuni se pot trata?
                    </Link>
                  </MenuItem>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/neurofeedback/frecventa-optima'}>
                      Ce este frecvența optimă?
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>

              <Link to={'/'}>
                <Image
                  w={'100px'}
                  objectFit={'contain'}
                  src={'/images/cerebrium-w.png'}
                  alt={'Auto Post'}
                />
              </Link>
              <Link to={`/heg`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  heg-nir
                </Text>
              </Link>
              <Menu>
                <MenuButton as={'Button'} rightIcon={<ChevronDownIcon />}>
                  FOTOBIOMODULARE <ChevronDownIcon />
                </MenuButton>
                <MenuList bg={'mainBlue'} border={'none'}>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/fotobiomodulare/ce-este-fotobiomodularea'}>
                      Ce este Fotobiomodularea?
                    </Link>
                  </MenuItem>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/fotobiomodulare/recomandari'}>
                      Cât timp este recomandată?
                    </Link>
                  </MenuItem>
                  <MenuItem
                    bg={'mainBlue'}
                    transition={'all ease-in-out .2s'}
                    _hover={{
                      color: 'secondBlue',

                      borderBottom: 'medium solid #00b3ff',
                    }}
                  >
                    <Link to={'/fotobiomodulare/utilizare-tsa'}>
                      Utilizarea în TSA
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link to={`/servicii`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  servicii
                </Text>
              </Link>
              <Link to={`/contact`}>
                <Text
                  w={'fit-content'}
                  transition={'border-bottom .2s ease-out'}
                  _hover={{
                    color: 'secondBlue',

                    borderBottom: 'medium solid #00b3ff',
                  }}
                >
                  contact
                </Text>
              </Link>
            </HStack>
          </Flex>
        ) : (
          <HamburgerIcon
            boxSize={6}
            _hover={'none'}
            _avtive={'none'}
            aria-label="Open Menu"
            onClick={toggle}
          />
        )}
      </Box>
    </Box>
  );
};

export default Navbar;
