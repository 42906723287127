import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

const Header = (props) => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      w={'full'}
      h={'400px'}
      bgImage={`url(/images/WebHeader.webp)`}
      bgPos={'center'}
      bgRepeat={'no-repeat'}
      bgSize={'cover'}
    >
      <Text
        fontSize={{ base: '2rem', md: '3rem' }}
        w={{ base: '330px', md: '550px' }}
        textAlign={'center'}
        color={'textLight'}
        fontWeight={'bold'}
      >
        {props.text}
      </Text>
    </Stack>
  );
};

export default Header;
