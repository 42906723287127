import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { Box } from '@chakra-ui/react';
import { Navbar, Sidebar, Footer } from './components';
import {
  About,
  Afectiuni,
  Brainmapping,
  Contact,
  Fbm,
  Frecventa,
  Heg,
  Home,
  Neurofeedback,
  Recomandari,
  Service,
  Services,
  Tsa,
} from './pages';
function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <ScrollToTop />
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        flexDir={'column'}
        w={'full'}
        minH={'calc(100vh - 150px - 200px)'}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/despre" element={<About />} />
          <Route path="/servicii" element={<Services />} />
          <Route path="/serviciu/:slug" element={<Service />} />
          <Route path="/contact" element={<Contact />} />

          {/* Servicii */}
          <Route path="/heg" element={<Heg />} />
          <Route path="/brain-mapping" element={<Brainmapping />} />

          {/* neurofeedback */}
          <Route
            path="/neurofeedback/ce-este-neurofeedback"
            element={<Neurofeedback />}
          />
          <Route path="/neurofeedback/afectiuni" element={<Afectiuni />} />
          <Route
            path="/neurofeedback/frecventa-optima"
            element={<Frecventa />}
          />

          {/* fotobiomodulare */}
          <Route
            path="/fotobiomodulare/ce-este-fotobiomodularea"
            element={<Fbm />}
          />
          <Route
            path="/fotobiomodulare/recomandari"
            element={<Recomandari />}
          />
          <Route path="/fotobiomodulare/utilizare-tsa" element={<Tsa />} />
        </Routes>
      </Box>
      <Footer />
    </Router>
  );
}

export default App;
