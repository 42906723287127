import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { Header, ServiceCard } from '../components';
import { serviciiData } from '../static/staticData';

const Services = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'flex-start'}
      w={'full'}
      className="servicesContainer"
      gap={0}
    >
      <Header text={'Serviciile oferite'} />
      <Stack
        w={'full'}
        alignItems={'center'}
        justifyContent={'center'}
        bg={
          'radial-gradient(rgb(144, 199, 71), rgb(128, 184, 68), rgb(113, 168, 66), rgb(99, 153, 62), rgb(85, 138, 58), rgb(73, 123, 54), rgb(62, 108, 50), rgb(51, 94, 45), rgb(42, 80, 40), rgb(33, 66, 34))'
        }
      >
        <HStack
          flexWrap={'wrap'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'3rem'}
          p={'3rem'}
          maxW={'100rem'}
        >
          {serviciiData.map((item) => (
            <ServiceCard
              link={item.link}
              title={item.title}
              online={item.online}
            />
          ))}
        </HStack>
      </Stack>
    </Stack>
  );
};

export default Services;
